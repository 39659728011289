import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from 'src/utils/stateToJson';

import {
  getAllState,
  createState,
  updateState,
  deleteState,
  createCountry,
  deleteCountry,
  getAllCountry,
  updateCountry,
  getAllDistrict,
  createDistrict,
  updateDistrict,
  deleteDistrict,
  getStateDetails,
  getAllStateSort,
  getCountryDetails,
  getAllCountrySort,
  getDistrictDetails,
  checkStateNameValid,
  checkCountryNameValid,
  checkDistrictNameValid,
} from '../api/region';

export const regionSlice = createSlice({
  name: 'region',
  initialState: {
    loading: false,
    error: {},
    country: {},
    countryDetail: {},
    isCountryValid: {},
    createLoading: false,
    countryLoading: false,
    states: {},
    statesDetail: {},
    isStateValid: {},
    stateLoading: false,
    districts: {},
    districtsDetail: {},
    isDistrictValid: {},
    districtLoading: false,

    statesSort: {},
    sortLoading: false,
    countrySort: {},
    countrySortLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getAllCountry.pending]: (state) => {
      state.countryLoading = true;
    },
    [getAllCountry.fulfilled]: (state, action) => {
      state.countryLoading = false;
      state.country = action.payload;
    },
    [getAllCountry.rejected]: (state, action) => {
      state.countryLoading = false;
      state.error = action.error;
    },
    // sort
    [getAllCountrySort.pending]: (state) => {
      state.countrySortLoading = true;
    },
    [getAllCountrySort.fulfilled]: (state, action) => {
      state.countryLoading = false;
      state.countrySort = action.payload;
    },
    [getAllCountrySort.rejected]: (state, action) => {
      state.countrySortLoading = false;
      state.error = action.error;
    },

    [getCountryDetails.pending]: (state) => {
      state.countryLoading = true;
    },
    [getCountryDetails.fulfilled]: (state, action) => {
      state.countryLoading = false;
      state.countryDetail = action.payload;
    },
    [getCountryDetails.rejected]: (state, action) => {
      state.countryLoading = false;
      state.error = action.error;
    },

    [createCountry.pending]: (state) => {
      state.createLoading = true;
    },
    [createCountry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.country;
      const newDistrictsList = jsonState?.categories;

      const modifiedDistrictList = {
        ...jsonState,
        country: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.createLoading = false;
      state.country = modifiedDistrictList;
      state.error = {};
    },
    [createCountry.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },

    [updateCountry.pending]: (state) => {
      state.createLoading = true;
    },
    [updateCountry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.country;
      const newDistrictsList = jsonState?.categories;

      const modifiedDistrictList = {
        ...jsonState,
        country: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.createLoading = false;
      state.country = modifiedDistrictList;
      state.error = {};
    },
    [updateCountry.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },

    [deleteCountry.pending]: (state) => {
      state.countryLoading = true;
    },
    [deleteCountry.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.country;

      const modifiedCountryList = {
        ...jsonState,
        countries: jsonState?.countries?.filter((i) => i.uid !== action.payload?.uid),
      };

      state.countryLoading = false;
      state.country = modifiedCountryList;
      state.error = {};
    },
    [deleteCountry.rejected]: (state, action) => {
      state.countryLoading = false;
      state.error = action.error;
    },

    // check country name
    [checkCountryNameValid.pending]: (state) => {
      state.createLoading = true;
    },
    [checkCountryNameValid.fulfilled]: (state, action) => {
      state.createLoading = false;
      state.isCountryValid = action.payload;
      state.error = {};
    },
    [checkCountryNameValid.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },

    // state

    [getAllState.pending]: (state) => {
      state.stateLoading = true;
    },
    [getAllState.fulfilled]: (state, action) => {
      state.stateLoading = false;
      state.states = action.payload;
    },
    [getAllState.rejected]: (state, action) => {
      state.stateLoading = false;
      state.error = action.error;
    },

    // sort
    [getAllStateSort.pending]: (state) => {
      state.sortLoading = true;
    },
    [getAllStateSort.fulfilled]: (state, action) => {
      state.sortLoading = false;
      state.statesSort = action.payload;
    },
    [getAllStateSort.rejected]: (state, action) => {
      state.sortLoading = false;
      state.error = action.error;
    },

    [getStateDetails.pending]: (state) => {
      state.stateLoading = true;
    },
    [getStateDetails.fulfilled]: (state, action) => {
      state.stateLoading = false;
      state.statesDetail = action.payload;
    },
    [getStateDetails.rejected]: (state, action) => {
      state.stateLoading = false;
      state.error = action.error;
    },

    [createState.pending]: (state) => {
      state.createLoading = true;
    },
    [createState.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.states;
      const newDistrictsList = jsonState?.states;

      const modifiedDistrictList = {
        ...jsonState,
        states: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.createLoading = false;
      state.states = modifiedDistrictList;
      state.error = {};
    },
    [createState.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },

    [updateState.pending]: (state) => {
      state.createLoading = true;
    },
    [updateState.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.states;
      const newDistrictsList = jsonState?.states;

      const modifiedDistrictList = {
        ...jsonState,
        states: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.createLoading = false;
      state.states = modifiedDistrictList;
      state.error = {};
    },
    [updateState.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },

    [deleteState.pending]: (state) => {
      state.stateLoading = true;
    },
    [deleteState.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.states;

      const modifiedStateList = {
        ...jsonState,
        states: jsonState?.states?.filter((i) => i.uid !== action.payload?.uid),
      };

      state.stateLoading = false;
      state.states = modifiedStateList;
      state.error = {};
    },
    [deleteState.rejected]: (state, action) => {
      state.stateLoading = false;
      state.error = action.error;
    },

    // check state name
    [checkStateNameValid.pending]: (state) => {
      state.createLoading = true;
    },
    [checkStateNameValid.fulfilled]: (state, action) => {
      state.createLoading = false;
      state.isStateValid = action.payload;
      state.error = {};
    },
    [checkStateNameValid.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },

    // district
    [getAllDistrict.pending]: (state) => {
      state.districtLoading = true;
    },
    [getAllDistrict.fulfilled]: (state, action) => {
      state.districtLoading = false;
      state.districts = action.payload;
    },
    [getAllDistrict.rejected]: (state, action) => {
      state.districtLoading = false;
      state.error = action.error;
    },

    [getDistrictDetails.pending]: (state) => {
      state.districtLoading = true;
    },
    [getDistrictDetails.fulfilled]: (state, action) => {
      state.districtLoading = false;
      state.districtsDetail = action.payload;
    },
    [getDistrictDetails.rejected]: (state, action) => {
      state.districtLoading = false;
      state.error = action.error;
    },

    [createDistrict.pending]: (state) => {
      state.createLoading = true;
    },
    [createDistrict.fulfilled]: (state, action) => {
      const jsonDistrict = covertToJSON(state)?.states;
      const newDistrictsList = jsonDistrict?.states;

      const modifiedDistrictList = {
        ...jsonDistrict,
        states: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.createLoading = false;
      state.districts = modifiedDistrictList;
      state.error = {};
    },
    [createDistrict.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },

    [updateDistrict.pending]: (state) => {
      state.createLoading = true;
    },
    [updateDistrict.fulfilled]: (state, action) => {
      const jsonDistrict = covertToJSON(state)?.states;
      const newDistrictsList = jsonDistrict?.states;

      const modifiedDistrictList = {
        ...jsonDistrict,
        states: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.createLoading = false;
      state.districts = modifiedDistrictList;
      state.error = {};
    },
    [updateDistrict.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },

    [deleteDistrict.pending]: (state) => {
      state.districtLoading = true;
    },
    [deleteDistrict.fulfilled]: (state, action) => {
      const jsonDistrict = covertToJSON(state)?.districts;

      const modifiedDistrictList = {
        ...jsonDistrict,
        districts: jsonDistrict?.districts?.filter((i) => i.uid !== action.payload?.uid),
      };

      state.districtLoading = false;
      state.districts = modifiedDistrictList;
      state.error = {};
    },
    [deleteDistrict.rejected]: (state, action) => {
      state.districtLoading = false;
      state.error = action.error;
    },

    // check state name
    [checkDistrictNameValid.pending]: (state) => {
      state.createLoading = true;
    },
    [checkDistrictNameValid.fulfilled]: (state, action) => {
      state.createLoading = false;
      state.isDistrictValid = action.payload;
      state.error = {};
    },
    [checkDistrictNameValid.rejected]: (state, action) => {
      state.createLoading = false;
      state.error = action.error;
    },
  },
});

export default regionSlice.reducer;
