import { useState } from 'react';
import PropTypes from 'prop-types';
// components
import Vimeo from '@u-wave/react-vimeo';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Box, Stack, Dialog, Tooltip, Typography, IconButton, DialogContent } from '@mui/material';

import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';

VideoPreview.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.any,
};

export default function VideoPreview({ open, setOpen, data }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const handleClose = () => {
    setOpen(false);
    setError('');
    setIsLoading(true);
  };

  const handleReady = (va) => {
    if (va?.id) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ overflow: 'unset' }}
        className="video_preview_modal "
      >
        <DialogContent sx={{ minWidth: '550px', p: 0, mb: 0, overflow: 'visible' }}>
          {isLoading ? (
            <Stack sx={{ height: '100%' }} alignItems="center" justifyContent="center">
              <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="center">
                <LoadingScreen />
                <Typography color="white" variant="body2">
                  Video is loading...
                </Typography>
              </Stack>
            </Stack>
          ) : (
            error && (
              <Stack sx={{ height: '100%' }} alignItems="center" justifyContent="center">
                <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="center">
                  <Typography color="white" variant="body2">
                    {error}
                  </Typography>
                </Stack>
              </Stack>
            )
          )}
          {data && (typeof data === 'string' || typeof data === 'number') ? (
            <Vimeo
              video={data}
              autoplay={false}
              width="550px"
              height={350}
              muted
              style={{ width: '100%', height: '100%' }}
              className="vimeo"
              dnt
              transparent
              title={false}
              byline={false}
              portrait={false}
              onLoaded={handleReady}
              onError={(v) => {
                setError('Video not found, check the Vimeo ID');
                setIsLoading(false);
              }}
            />
          ) : (
            <Box
              sx={{
                height: '350px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#919eab1a',
                borderRadius: '13px',
              }}
            >
              <Tooltip title="Upon entering the video ID, the preview will become visible.">
                <Typography variant="body2" color="#637381">
                  Video Preview
                </Typography>
              </Tooltip>
            </Box>
          )}
        </DialogContent>
        <IconButton
          size="small"
          sx={{
            height: '40px',
            width: '40px',
            color: '#fff',
            backgroundColor: '#00000066',
            position: 'absolute',
            bottom: '-6rem',
            left: '50%',
          }}
          onClick={handleClose}
        >
          <Iconify icon="mingcute:close-line" width={16} />
        </IconButton>
      </Dialog>
    </div>
  );
}
