import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { del, put, get, post } from '../http';
import { activeSnack } from '../store/common';

export const getAllPickupLocations = createAsyncThunk(
  'pickup-location/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/pickup-location/admin/all?page=${page && page}&search=${search || ''}&limit=${
          limit || 20
        }`,
        state
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createPickupLocation = createAsyncThunk(
  'pickup-location/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/pickup-location/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        router.push({
          pathname: paths.dashboard?.settings?.pickupLocation,
          query: {},
        });
        dispatch(activeSnack({ type: 'success', message: 'Pickup Location Created Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getPickupLocationDetails = createAsyncThunk(
  'review/single',
  async ({ locationId, dispatch }) => {
    try {
      const response = await get(`pickup-location/admin/single/${locationId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updatePickupLocationDetails = createAsyncThunk(
  'group/update',
  async ({ state, locationId, dispatch, router }) => {
    try {
      const URL = `/pickup-location/admin/${locationId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (router) {
          router.push({
            pathname: paths.dashboard?.settings.pickupLocation,
            query: {},
          });
        }
        dispatch(activeSnack({ type: 'success', message: 'Pickup Location Updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );

      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getPickupLocationCounts = createAsyncThunk(
  'review/count',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/pickup-location/admin/count/all`, state);

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updatePickupLocationStatus = createAsyncThunk(
  'pickup-location/status/update',
  async ({ state, locationId, dispatch }) => {
    try {
      const URL = `/pickup-location/admin/status/active/${locationId}`;
      const response = await post(URL, state);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Pickup Location Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archivePickupLocation = createAsyncThunk(
  'pickup-location/archive',
  async ({ locationId, dispatch, handleClose }) => {
    try {
      const response = await post(`/pickup-location/admin/delete/${locationId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Pickup Location Archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restorePickupLocation = createAsyncThunk(
  'pickup-location/unarchive',
  async ({ locationId, dispatch, handleClose }) => {
    try {
      const response = await post(`/pickup-location/admin/restore/${locationId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Pickup Location Restored' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deletePickupLocation = createAsyncThunk(
  'pickup-location/delete',
  async ({ locationId, dispatch, handleClose }) => {
    try {
      const response = await del(`/pickup-location/admin/hard-delete/${locationId}/`);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Pickup Location Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
