import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  List,
  Stack,
  Divider,
  ListItem,
  Accordion,
  Typography,
  ListItemText,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import PublicLayout from 'src/layouts/PublicLayout';

const ReleaseNote = () => {
  const [expanded, setExpanded] = useState(true);
  const isMobile = useResponsive('sm', 'xs');

  return (
    <PublicLayout>
      <Typography variant="h4">Release Note for Buildex E-Commerce Admin Dashboard</Typography>

      <Stack
        sx={{
          padding: '16px',
          maxWidth: '1000px',
          margin: '0 auto',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          mt: 5,
        }}
      >
        {/* Accordion for Version 1.0 */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Stack direction={isMobile ? 'column' : 'row'}>
              <Typography variant="subtitle1">E-Commerce Admin Dashboard: Version 1.0</Typography>
              <Typography variant="subtitle1" ml={2}>
                31.08.2024
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph textAlign="start">
              We are excited to announce the release of <strong>Version 1.0</strong> of the Buildex
              E-Commerce Admin Dashboard. This update introduces several new features to enhance
              your experience and provide more control over your e-commerce platform.
            </Typography>

            <Typography textAlign="start" variant="h5" gutterBottom sx={{ marginTop: '16px' }}>
              Features:
            </Typography>

            <ListItem>
              <ListItemText
                primary="Inventory Module"
                secondary="Efficiently manage all aspects of your inventory, including Products: Add, edit, and organize your product listings with essential details like pricing, descriptions, and stock levels; Categories: Group products into categories for improved navigation and easier access for customers; Brands: Maintain a comprehensive list of brands associated with your products to enhance brand visibility and customer recognition; Banners: Create and manage promotional banners to highlight sales, new arrivals, and special offers on your website; Collections: Showcase related products together in themed collections to enhance customer shopping experiences; Special Sales: Set up and manage special sale events with limited-time discounts to boost customer engagement; Product Groups: Organize similar products into groups for streamlined management and better visibility; Product Labels: Assign labels to products to emphasize features like new arrivals, best sellers, or limited editions; Product Variations: Handle different variations of a product, such as size, color, and style, to provide customers with diverse choices."
              />
            </ListItem>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Version 2.0 */}
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Stack direction={isMobile ? 'column' : 'row'}>
              <Typography variant="subtitle1">E-Commerce Admin Dashboard: Version 2.0</Typography>
              <Typography variant="subtitle1" ml={2}>
                10.10.2024
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph textAlign="start">
              We are excited to announce the release of <strong>Version 2.0</strong> of the Buildex
              E-Commerce Admin Dashboard. This update introduces several new features to enhance
              your experience and provide more control over your e-commerce platform.
            </Typography>

            <Typography textAlign="start" variant="h5" gutterBottom sx={{ marginTop: '16px' }}>
              New Features:
            </Typography>

            <List
              sx={{
                height: 250,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  borderRadius: '4px',
                },
              }}
            >
              <ListItem>
                <ListItemText
                  primary="Image Crop Option"
                  secondary="Easily crop images to the desired size and aspect ratio, ensuring your product visuals are optimized for display. This feature enhances your product presentation and improves user engagement."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Customizable Site Settings"
                  secondary="Manage your website’s layout, branding, and functionality through an intuitive settings panel. This allows greater flexibility and control over the look and feel of your online store."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Order System"
                  secondary="The order management system has been upgraded to streamline order tracking and processing. Enjoy a smoother experience in managing customer orders, status updates, and fulfillment workflows."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Coupons Management"
                  secondary="We’ve added a feature to create, manage, and track discount coupons. This tool will help you provide promotions and incentives to your customers, boosting sales and engagement."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Product Review"
                  secondary="Share your thoughts about this product. Your feedback helps others make informed decisions and improves our offerings."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Product Group"
                  secondary="Organize similar products into groups to enhance customer browsing. This feature allows you to showcase related items together, making it easier for customers to find and compare products."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Header & Footer Customization"
                  secondary="Now you can easily customize the content and design of the header and footer of your site, adding important links, social media, contact information, and more."
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Upcoming Version 3.0 */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <Typography variant="subtitle1">Upcoming Version: 3.0</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph textAlign="start">
              We are excited to announce the release of <strong>Version 3.0</strong> of the Buildex
              E-Commerce Admin Dashboard. This update introduces several new features to enhance
              your experience and provide more control over your e-commerce platform.
            </Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary="Customizable Page Sections"
                  secondary="Manage the layout, branding, and functionality of your website’s pages through a user-friendly settings panel. This gives you greater flexibility and control over the design and structure of individual sections, enhancing the overall look and experience of your online store."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Product Variants"
                  secondary="Product Variants let you offer different options for a product, like size or color, from a single page. You can manage unique prices, images, and stock for each variant, simplifying inventory while enhancing the customer experience."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Payment Integration"
                  secondary="Payment Integration enables secure, seamless transactions with support for multiple methods like credit cards and digital wallets. It simplifies payment management while ensuring security and compliance."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Enhanced Order System"
                  secondary="The order management system has been upgraded to streamline order tracking and processing. Enjoy a smoother experience in managing customer orders, status updates, and fulfillment workflows."
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </PublicLayout>
  );
};

export default ReleaseNote;
