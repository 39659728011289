import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Stack, Button, Divider, MenuItem, Typography } from '@mui/material';

import Chart, { useChart } from 'src/components/chart';
import { ChartLegends } from 'src/components/chart/chart-legends';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export function EcommerceYearlySales({
  title,
  subheader,
  graphFilter2,
  graphFilterLabel,
  setGraphFilterLabel,
  graphFilterValue,
  setGraphFilterValue,
  thisYearGraphData,
  lastYearGraphData,
  dashboardCounts,
  chart,
  ...other
}) {
  const theme = useTheme();
  const popover2 = usePopover();

  const chartColors = chart.colors ?? [theme.palette.primary.main, theme.palette.warning.main];

  const chartOptions = useChart({
    colors: chartColors,
    xaxis: { categories: chart.categories },
    ...chart.options,
  });

  const handleChangeFilter2 = (newValue) => {
    setGraphFilterValue(newValue?.value);
    setGraphFilterLabel(newValue?.label);
  };

  const name = graphFilterValue === 'MONTH' ? 'Month' : 'Year';

  const data = [
    {
      name: `This ${name}`,
      data: thisYearGraphData,
    },
    {
      name: `Last ${name}`,
      data: lastYearGraphData,
    },
  ];

  const handleSaleValue = () => {
    if (graphFilterValue === 'MONTH') {
      return {
        current_value: dashboardCounts?.month_graph_data?.this_month_total_sales || 0,
        previous_value: dashboardCounts?.month_graph_data?.last_month_total_sales || 0,
      };
    }
    return {
      current_value: dashboardCounts?.year_graph_data?.this_year_total_sales || 0,
      previous_value: dashboardCounts?.year_graph_data?.last_year_total_sales || 0,
    };
  };

  return (
    <Card {...other}>
      {/* <FormProvider {...methods}> */}
      {/* <Stack flexDirection="row" alignItems="center" justifyContent="space-between"> */}
      <Box px={3} py={3}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          {/* Left-aligned content */}
          <Stack>
            <Typography variant="h6">Total Sales</Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              onClick={popover2.onOpen}
              sx={{
                border: '0.5px solid #D3D3D3',
                width: 'auto',
              }}
            >
              <Typography variant="caption" fontSize={14}>
                {graphFilterLabel}
              </Typography>
              &nbsp;&nbsp;
              {popover2?.open ? (
                <KeyboardArrowUpIcon fontSize="14px" />
              ) : (
                <KeyboardArrowDownIcon fontSize="14px" />
              )}
            </Button>
            <CustomPopover
              anchorEl={popover2.anchorEl}
              open={popover2.open}
              onClose={popover2.onClose}
              arrow="top-right"
              sx={{ width: 150 }}
            >
              {graphFilter2?.map((item, key) => (
                <MenuItem
                  key={key}
                  selected={item?.value === graphFilterValue}
                  onClick={() => {
                    popover2.onClose();
                    handleChangeFilter2(item);
                  }}
                >
                  {item?.label}
                  <Divider sx={{ mt: 0.2 }} />
                </MenuItem>
              ))}
            </CustomPopover>
          </Stack>
        </Stack>
      </Box>

      {/* </Stack> */}
      {/* </FormProvider> */}
      <ChartLegends
        colors={chartOptions?.colors}
        labels={data.map((item) => item.name)}
        values={[handleSaleValue()?.current_value, handleSaleValue()?.previous_value]}
        sx={{ px: 3, gap: 3 }}
      />
      <Chart
        type="area"
        // series={currentSeries?.data}
        series={data}
        options={{
          ...chartOptions,
          yaxis: {
            ...chartOptions.yaxis,
            labels: {
              formatter: (value) => value.toFixed(3), // Format y-axis labels to 3 digits maximum
            },
          },
          tooltip: {
            ...chartOptions.tooltip,
            y: {
              formatter: (value) => value.toFixed(3), // Format tooltip values to 3 digits maximum
            },
          },
        }}
        height={360}
        sx={{ py: 2.5, pl: 1, pr: 2.5 }}
      />
    </Card>
  );
}

EcommerceYearlySales.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chart: PropTypes.any,
  graphFilter2: PropTypes.array,
  graphFilterLabel: PropTypes.string,
  setGraphFilterLabel: PropTypes?.func,
  graphFilterValue: PropTypes?.string,
  setGraphFilterValue: PropTypes?.func,
  lastYearGraphData: PropTypes.array,
  thisYearGraphData: PropTypes.array,
  dashboardCounts: PropTypes.any,
};
