import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function RHFTextField({
  name,
  helperText = '',
  multiline = false,
  rows,
  showDollarSymbol = false,
  label,
  type = 'text',
  inputShrink = false,
  sx,
  isValidationRequired = false,
  isValid = true,
  validationMsg = '',
  onChangeInput,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        // Determine final helper text
        const finalHelperText =
          error?.message || (isValidationRequired && !isValid ? validationMsg : helperText);

        return (
          <TextField
            {...field}
            fullWidth
            type={type}
            variant="outlined"
            label={label}
            multiline={multiline}
            rows={rows}
            value={
              type === 'number' && (!field.value && field.value !== 0)
                ? ''
                : field.value
            }
            onChange={(event) => {
              const newValue = event.target.value;
              if (onChangeInput) {
                onChangeInput(newValue);
              }
              if (type === 'number' && newValue === '') {
                field.onChange('');
              } else if (type === 'number') {
                field.onChange(Number(newValue));
              } else {
                field.onChange(newValue);
              }
            }}
            error={!!error || (isValidationRequired && !isValid)} // Combine field error and custom validation
            helperText={finalHelperText || ''} // Ensure helperText is never null/undefined
            InputProps={
              showDollarSymbol
                ? { startAdornment: <InputAdornment position="start">₹</InputAdornment> }
                : null
            }
            InputLabelProps={inputShrink ? { shrink: true } : {}}
            sx={sx}
            {...other}
          />
        );
      }}
    />
  );
}

RHFTextField.propTypes = {
  name: PropTypes.any, // Name must be required for Controller
  helperText: PropTypes.any, // String type for helper text
  label: PropTypes.any,
  type: PropTypes.any,
  multiline: PropTypes.any,
  rows: PropTypes.number,
  showDollarSymbol: PropTypes.any,
  inputShrink: PropTypes.any,
  sx: PropTypes.any,
  isValidationRequired: PropTypes.any,
  isValid: PropTypes.any,
  validationMsg: PropTypes.any,
  onChangeInput: PropTypes.any,
};
