import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

// Import the user icon

import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tab, Tabs, alpha, Stack } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import SEOPage from 'src/pages/Common/Others/SEOPage';
import { getSeoItemModule } from 'src/server/api/seoData';
import { createBlog, getBlogDetails, updateBlogDetails } from 'src/server/api/blogs';

import FormProvider from 'src/components/hook-form';
import { LoadingScreen } from 'src/components/loading-screen';

import Content from './sections/CRUD/Content';
import BasicDetails from './sections/CRUD/BasicDetails';
import BannerList from '../Banners/sections/bannerList';

// ----------------------------------------------------------------------

export default function BlogCRUDForm() {
  const dispatch = useDispatch();
  const params = useParams();
  const blogId = params.id;
  const router = useRouter();
  const [rating, setRating] = useState(null);

  const loading = useSelector((state) => state.review.loading);
  const blogDetails = useSelector((state) => state.blog.blogDetails);
  const seoData = useSelector((state) => state.seoData.seoData);

  useEffect(() => {
    if (blogId) {
      const credentials = {
        blogId,
        dispatch,
      };
      dispatch(getBlogDetails(credentials));
    }
  }, [dispatch, blogId]);

  // state
  const [imagePreview, setImagePreview] = useState([]); // data : public_id:'', url:"", alt_text:{arabic:"",english:""}

  useEffect(() => {
    if (blogDetails?.images?.length >= 1) {
      setImagePreview(blogDetails?.images);
    }
  }, [blogDetails?.images]);

  const blogSchema = Yup.object().shape({
    title: Yup.object().shape({
      english: Yup.string(),
      arabic: Yup.string(),
    }),
    contentEnglish: Yup.string()?.required('Content is required'),
    contentArabic: Yup.string().required('Content is required'),
    subtitle: Yup.object().shape({
      english: Yup.string(),
      arabic: Yup.string(),
    }),
    link: Yup.string(),
    link_type: Yup.string(),

    is_featured: Yup.bool(),
    is_active: Yup.bool(),
    // product: Yup.object().shape({
    //   label: Yup.string(),
    //   value: Yup.string(),
    // }),
  });

  const defaultProduct = useMemo(
    () =>
      blogDetails?.products?.map((item) => ({
        label: item?.name?.english || '',
        value: item?.uid || '',
      })) || [],
    [blogDetails.products]
  );

  const defaultValues = useMemo(() => {
    if (blogId) {
      return {
        title: {
          english: blogDetails?.title?.english || '',
          arabic: blogDetails?.title?.arabic || '',
        },
        subtitle: {
          english: blogDetails?.title?.english || '',
          arabic: blogDetails?.title?.arabic || '',
        },
        contentEnglish: blogDetails?.content?.english,
        contentArabic: blogDetails?.content?.arabic,
        is_active: blogDetails?.is_active || false,
        link: blogDetails?.link || '',
        link_type: blogDetails?.link_type || 'INTERNAL',
        is_featured: blogDetails?.is_featured || false,
        products: defaultProduct || [],
      };
    }
    return {
      title: '',
      subtitle: '',
      is_active: false,
      is_featured: false,
      link: '',
      link_type: 'INTERNAL',
      contentArabic: '',
      contentEnglish: '',
    };
  }, [
    blogDetails?.content?.arabic,
    blogDetails?.content?.english,
    blogDetails?.is_active,
    blogDetails?.is_featured,
    blogDetails?.link,
    blogDetails?.link_type,
    blogDetails?.title?.arabic,
    blogDetails?.title?.english,
    blogId,
    defaultProduct,
  ]);

  const methods = useForm({
    resolver: yupResolver(blogSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  // default data
  useEffect(() => {
    if (blogDetails?.image?.public_id) {
      setImagePreview([blogDetails?.image]);
    }
  }, [blogDetails?.image]);

  useEffect(() => {
    if (Object.keys(defaultValues).length) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const onSubmit = async (data) => {
    const initialData = {
      ...data,
      image: imagePreview[0],
      products: data?.products?.map((i) => i?.value),
      content: {
        english: data?.contentEnglish,
        arabic: data?.contentArabic,
      },
    };
    ['contentArabic', 'contentEnglish'].forEach((descKey) => {
      delete initialData[descKey];
    });

    const updateInitialData = {
      ...data,
      image: imagePreview[0] ? imagePreview[0] : [],
      products: data?.products?.map((i) => i?.value),
      content: {
        english: data?.contentEnglish,
        arabic: data?.contentArabic,
      },
    };
    ['contentArabic', 'contentEnglish'].forEach((descKey) => {
      delete updateInitialData[descKey];
    });

    // Prepare data for dispatch
    const createData = {
      state: initialData,
      dispatch,
      router,
    };
    const updateData = {
      state: updateInitialData,
      dispatch,
      router,
      blogId,
    };

    if (blogId) {
      // Update case
      dispatch(updateBlogDetails(updateData));
    } else {
      // Create case
      dispatch(createBlog(createData));
    }
  };

  const TAB_OPTIONS = [
    { value: 'basicDetails', label: 'Basic Details' },
    { value: 'content', label: 'Content ' },
  ];
  if (blogId) {
    TAB_OPTIONS.push({ value: 'banners', label: 'Banners' }, { value: 'seo', label: 'SEO ' });
  }

  const [tabChange, setTabChange] = useState({
    value: 'basicDetails',
    badge: '0',
  });

  const handleFilterStatus = (e, value) => {
    setTabChange({
      value,
    });
  };

  useEffect(() => {
    const filterDataObj = {
      module: 'BLOG',
      module_id: blogId,
    };

    const credentials = {
      search: '',
      dispatch,
      state: filterDataObj,
    };

    if (blogId) {
      dispatch(getSeoItemModule(credentials));
    }
  }, [blogId, dispatch]);

  const currentSeoData = seoData;

  return (
    <>
      <Typography variant="subtitle1">{blogId ? 'Update Blog' : 'Create BLog'}</Typography>
      <Typography variant="caption">Please complete the Blog information.</Typography>
      <Grid container maxWidth="xl" spacing={2} mt={2}>
        <Grid item xs={12} md={8}>
          {loading ? (
            <Stack sx={{ height: '50px' }}>
              <LoadingScreen />
            </Stack>
          ) : (
            <Card sx={{ pb: 2 }}>
              <Tabs
                value={tabChange.value}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {TAB_OPTIONS.map((tab, key) => (
                  <Tab
                    key={key}
                    iconPosition="end"
                    value={tab.value}
                    label={
                      tab.value === 'content' ? (
                        <>
                          {tab.label}&nbsp;
                          <span style={{ color: 'red' }}>*</span>
                        </>
                      ) : (
                        tab.label
                      )
                    }
                  />
                ))}
              </Tabs>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack sx={{ width: '400px', top: '0', position: 'absolute', right: 0 }}>
                  <Grid
                    xs={12}
                    md={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      px: 3,
                    }}
                  >
                    {errors?.name?.english?.message ? (
                      <Typography variant="caption" color="red">
                        Please fill the required fields!
                      </Typography>
                    ) : (
                      <Stack />
                    )}
                    {tabChange.value === 'seo' || tabChange.value === 'banners' ? (
                      ''
                    ) : (
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="small"
                        loading={isSubmitting || loading}
                        sx={{
                          ml: 2,
                          width: '150px',
                          backgroundColor: '#106E58',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#0b4e3e',
                          },
                        }}
                      >
                        Save
                      </LoadingButton>
                    )}
                  </Grid>
                </Stack>
                <>
                  {/* // Tab component */}
                  {tabChange?.value === 'basicDetails' && (
                    <BasicDetails
                      isUpdate={blogDetails?.uid && true}
                      watch={watch}
                      setValue={setValue}
                      control={control}
                      setRating={setRating}
                      rating={rating}
                      imagePreview={imagePreview}
                      setImagePreview={setImagePreview}
                      // handleRemoveProduct={handleRemoveItemToList}
                    />
                  )}
                  {tabChange?.value === 'content' && <Content />}
                  {tabChange?.value === 'banners' && (
                    <BannerList moduleName="BLOG" moduleId={blogId} />
                  )}
                </>
                <Grid
                  xs={12}
                  md={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                  }}
                >
                  {errors?.name?.english?.message ? (
                    <Typography variant="caption" color="red">
                      Please fill the required fields!
                    </Typography>
                  ) : (
                    <Stack />
                  )}
                </Grid>
              </FormProvider>
              {tabChange.value === 'seo' && (
                <SEOPage
                  moduleName="BLOG"
                  moduleId={blogId} // blog uid
                  updateData={currentSeoData} // current seo data
                />
              )}
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}
