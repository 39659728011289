import React, { useState } from 'react';

import { Tab, Grid, Tabs, alpha, Stack } from '@mui/material';

import { RHFEditor } from 'src/components/hook-form';

function Content() {
  const TAB_OPTIONS = [
    { value: 'english', label: 'English ' },
    { value: 'arabic', label: 'Arabic' },
  ];

  const [tabChange, setTabChange] = useState({
    value: 'english',
    badge: '0',
  });

  const handleFilterStatus = (e, value) => {
    setTabChange({
      value,
    });
  };

  return (
    <Stack spacing={1.5} p={2}>
      <Tabs
        value={tabChange.value}
        onChange={handleFilterStatus}
        sx={{
          px: 2.5,
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {TAB_OPTIONS.map((tab, key) => (
          <Tab key={key} iconPosition="end" value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <Grid container maxWidth="xl" spacing={2}>
        {tabChange.value === 'english' && (
          <Grid xs={12} md={12} item className="blog-description-input">
            <Stack spacing={1.5}>
              <RHFEditor simple placeholder="Write description" required name="contentEnglish" />
            </Stack>
          </Grid>
        )}
        {tabChange?.value === 'arabic' && (
          <Grid xs={12} md={12} item className="blog-description-input">
            <Stack spacing={1.5} className="editor-arabic">
              <RHFEditor simple name="contentArabic" required placeholder="Write description" />
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}

export default Content;
