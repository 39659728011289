import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createProductVariation = createAsyncThunk(
  'product-variation/create',
  async ({ state, dispatch, handleClose, handleClear, filterData }) => {
    try {
      const URL = `/product-variation/admin/new`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        const filterDataObj = {
          filter_data: filterData,
        };
        dispatch(
          getAllProductVariation({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            state: filterDataObj,
          })
        );
        dispatch(
          activeSnack({ type: 'success', message: 'Product Variation Created Successfully' })
        );
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProductVariation = createAsyncThunk(
  'product-variation/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/product-variation/admin/value/all?page=${page || 1}&search=${search || ''}&limit=${
          limit || 5
        }`,
        state
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProductVariationDetails = createAsyncThunk(
  'product-variation/single',
  async ({ productId, dispatch }) => {
    try {
      const response = await get(`/product-variation/admin/single/${productId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProductVariationDetails = createAsyncThunk(
  'product-variation/update',
  async ({
    state,
    productVariationId,
    dispatch,
    handleClose,
    handleClear,
    handleMenuClose,
    router,
  }) => {
    try {
      const URL = `/product-variation/admin/${productVariationId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClear) {
          handleClear();
        }
        router.push({
          pathname: paths.dashboard.inventory.product_attributes,
          query: {},
        });
        dispatch(
          getAllProductVariation({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_product_variation'),
            state: '',
          })
        );
        dispatch(
          activeSnack({ type: 'success', message: 'Product Variation Updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProductVariationStatus = createAsyncThunk(
  'product-variation/status-update',
  async ({ state, dispatch, productVariationId, tab }) => {
    try {
      const URL = `product-variation/admin/status/active/${productVariationId}`;
      const response = await post(URL, state);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status Updated Successfully' }));
        return { response, tab };
      }
      return response;
    } catch (error) {
      dispatch(
        activeSnack({
          type: 'error',
          message: error?.response?.data?.message || 'Something went wrong',
        })
      );
      throw error?.response?.data?.message;
    }
  }
);

export const checkVariationNameValid = createAsyncThunk(
  'product-variation/check/name',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/product-variation/admin/english-title/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProductVariationCounts = createAsyncThunk(
  'product-variation/count',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/product-variation/admin/count/all`, state);

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archiveProductVariation = createAsyncThunk(
  'product-variation/archive',
  async ({ productVariationId, dispatch, handleClose }) => {
    try {
      const response = await post(`/product-variation/admin/delete/${productVariationId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Product Archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreProductVariation = createAsyncThunk(
  'product-variation/unarchive',
  async ({ productVariationId, dispatch, handleClose }) => {
    try {
      const response = await post(`/product-variation/admin/restore/${productVariationId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Product Restored' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteProductVariation = createAsyncThunk(
  'product-variation/delete',
  async ({ productVariationId, dispatch, handleClose }) => {
    try {
      const response = await del(`/product-variation/admin/hard-delete/${productVariationId}/`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'Product Variation Deleted Successfully' })
        );
        return response?.uid;
      }

      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
