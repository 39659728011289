import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createPages,
  deletePages,
  getAllPages,
  archivePages,
  restorePages,
  getPagesCounts,
  getPagesDetails,
  updatePageStatus,
  updatePagesDetails,
} from '../api/pages';

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    loading: false,
    error: {},
    pages: {},
    pageCounts: {},
    pagesDetails: {},
    statusLoading: false,
    delLoading: false,
  },
  reducers: {
    clearProductPagesError: (state) => {
      state.error = true;
    },
    clearPagesDetailsState: (state) => {
      state.pagesDetails = {};
    },
  },
  extraReducers: {
    // create
    [createPages.pending]: (state) => {
      state.loading = true;
    },
    [createPages.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedDistrictList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.pages = modifiedDistrictList;
      state.error = {};
    },
    [createPages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // all list
    [getAllPages.pending]: (state) => {
      state.loading = true;
    },
    [getAllPages.fulfilled]: (state, action) => {
      state.loading = false;
      state.pages = action.payload;
      state.error = {};
    },
    [getAllPages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get pages count
    [getPagesCounts.pending]: (state) => {
      state.loading = true;
    },
    [getPagesCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.pageCounts = action.payload;
      state.error = {};
    },
    [getPagesCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // singleView
    [getPagesDetails.pending]: (state) => {
      state.loading = true;
    },
    [getPagesDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.pagesDetails = action.payload;
      state.error = {};
    },
    [getPagesDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update
    [updatePagesDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProductPagesDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProductLabelDetails = action.payload;
    //   state.error = {};
    // },
    [updatePagesDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.groupDetails = action.payload;
      state.pages = action.payload;
      state.error = {};
    },

    [updatePagesDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update status
    [updatePageStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [updatePageStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.pages;
      const modifiedProductPagesList = {
        ...jsonState,
        pages: jsonState?.pages?.map((item) =>
          item?.uid === action.payload?.uid
            ? { ...item, is_active: action.payload?.is_active }
            : item
        ),
      };
      state.statusLoading = false;
      state.pages = modifiedProductPagesList;
      state.error = {};
    },

    [updatePageStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // archive
    [archivePages.pending]: (state) => {
      state.delLoading = true;
    },
    // archive
    [archivePages.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.pages;
      const modifiedProductList = {
        ...jsonState,
        pages: jsonState.pages?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.pages = modifiedProductList;
      state.error = {};
    },
    [archivePages.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore

    [restorePages.pending]: (state) => {
      state.delLoading = true;
    },
    [restorePages.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.pages;
      const modifiedPagesList = {
        ...jsonState,
        pages: jsonState.pages?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.pages = modifiedPagesList;
      state.error = {};
    },
    [restorePages.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // delete
    [deletePages.pending]: (state) => {
      state.loading = true;
    },
    [deletePages.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.pages;
      const modifiedDistrictList = {
        ...jsonState,
        pages: jsonState.pages?.filter((pages) => pages.uid !== action.payload),
      };
      state.loading = false;
      state.pages = modifiedDistrictList;
      state.error = {};
    },
    [deletePages.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearProductPagesError, clearPagesDetailsState } = pagesSlice.actions;

export default pagesSlice.reducer;
