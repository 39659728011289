import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createSection = createAsyncThunk(
  'sections/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/section/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        const filterDataObj = {
          filter_data: {
            is_deleted: false,
          },
          sort_data: { priority: 1 },
        };
        const cred = {
          pageId: response?.page,
          state: filterDataObj,
          dispatch,
        };
        dispatch(getAllSectionByPage(cred));
        dispatch(activeSnack({ type: 'success', message: 'Section Created Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSections = createAsyncThunk(
  'sections/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/section/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSectionByPage = createAsyncThunk(
  'page/sections/list',
  async ({ page, search, dispatch, limit, state, pageId }) => {
    try {
      const response = await post(
        `/section/admin/page/${pageId}?page=${page || 1}&search=${search || ''}&limit=${
          limit || 100
        }`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getDefaultSectionByPage = createAsyncThunk(
  'defaults/sections/list',
  async ({ page, search, dispatch, limit, state, pageId }) => {
    try {
      const response = await post(
        `/section/admin/page/${pageId}?page=${page || 1}&search=${search || ''}&limit=${
          limit || 100
        }`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSectionCounts = createAsyncThunk('sections/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/section/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getSectionDetails = createAsyncThunk(
  'sections/single',
  async ({ sectionId, dispatch }) => {
    try {
      const response = await get(`section/admin/single/${sectionId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSectionDetails = createAsyncThunk(
  'sections/update',
  async ({ state, sectionId, dispatch, handleClose }) => {
    try {
      const URL = `/section/admin/${sectionId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        const filterDataObj = {
          filter_data: {
            is_deleted: false,
          },
          sort_data: { priority: 1 },
        };
        const cred = {
          pageId: response?.page,
          state: filterDataObj,
          dispatch,
        };
        dispatch(getAllSectionByPage(cred));
        dispatch(activeSnack({ type: 'success', message: 'Section Updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );

      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSectionStatus = createAsyncThunk(
  'sections/status/update',
  async ({ state, sectionId, dispatch }) => {
    try {
      const URL = `section/admin/status/active/${sectionId}`;
      const response = await post(URL, state);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archiveSection = createAsyncThunk(
  'sections/archive',
  async ({ sectionId, dispatch, handleClose }) => {
    try {
      const response = await post(`/section/admin/delete/${sectionId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Section Archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreSection = createAsyncThunk(
  'sections/unarchive',
  async ({ sectionId, dispatch, handleClose }) => {
    try {
      const response = await post(`/section/admin/restore/${sectionId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Section Restored' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSection = createAsyncThunk(
  'sections/delete',
  async ({ sectionId, dispatch, handleClose }) => {
    try {
      const response = await del(`/section/admin/hard-delete/${sectionId}/`);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Section Deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
