import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import BlogList from './blogList';

export default function Blogs() {
  return (
    <>
      <MetaHelmet title="Blogs" />
      <BlogList />
    </>
  );
}
