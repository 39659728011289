import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createPages = createAsyncThunk('pages/create', async ({ state, dispatch, router }) => {
  try {
    const URL = `/page/admin/new`;
    const response = await post(URL, state);
    if (response?.isSuccess) {
      router.push({
        pathname: paths.dashboard?.settings?.page_update(response.uid),
        query: {},
      });
      dispatch(activeSnack({ type: 'success', message: 'Pages Created Successfully' }));
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllPages = createAsyncThunk(
  'pages/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/page/admin/all?page=${page || 1}&search=${search || ''}&limit=${limit || 5}`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getPagesCounts = createAsyncThunk('pages/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/page/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getPagesDetails = createAsyncThunk('pages/single', async ({ pageId, dispatch }) => {
  try {
    const response = await get(`page/admin/single/${pageId}`);
    if (response?.isSuccess) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const updatePagesDetails = createAsyncThunk(
  'pages/update',
  async ({ state, pageId, dispatch, router }) => {
    try {
      const URL = `/page/admin/${pageId}`;

      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (router) {
          router.push({
            pathname: paths.dashboard?.settings?.pages,
            query: {},
          });
        }
        dispatch(activeSnack({ type: 'success', message: 'Pages Updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );

      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updatePageStatus = createAsyncThunk(
  'pages/status/update',
  async ({ state, pageId, dispatch }) => {
    try {
      const URL = `page/admin/status/active/${pageId}`;
      const response = await post(URL, state);

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archivePages = createAsyncThunk(
  'pages/archive',
  async ({ pageId, dispatch, handleClose }) => {
    try {
      const response = await post(`/page/admin/delete/${pageId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Pages Archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restorePages = createAsyncThunk(
  'pages/unarchive',
  async ({ pageId, dispatch, handleClose }) => {
    try {
      const response = await post(`/page/admin/restore/${pageId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Pages Restored' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deletePages = createAsyncThunk(
  'pages/delete',
  async ({ pageId, dispatch, handleClose }) => {
    try {
      const response = await del(`/page/admin/hard-delete/${pageId}/`);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Pages Deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
