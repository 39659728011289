// eslint-disable-next-line import/no-unresolved
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from '../http';
import { activeSnack } from '../store/common';

export const getInventoryCountData = createAsyncThunk(
  'static/inventory/count',
  async ({ dispatch }) => {
    try {
      const response = await get(`/static/admin/inventory-counts`);
      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSidebarItemCountData = createAsyncThunk(
  'static/sidebar/count',
  async ({ enqueueSnackbar, userType }) => {
    try {
      const response = await get(`/static/dashboard/counts?user_type=${userType && userType}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getDashboardData = createAsyncThunk(
  'static/dashboard',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/static/admin/dashboard-data');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getDashboardSyncData = createAsyncThunk(
  'static/dashboard-sync',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/static/admin/dashboard-data/sync');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// partner charts
export const getPartnerEarningChartData = createAsyncThunk(
  'earning/admin',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`earning/admin/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getPartnerSalesChartData = createAsyncThunk(
  'sale/admin',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`sale/admin/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const getPartnerBalanceChartData = createAsyncThunk(
  'earning/admin/increment',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`/earning/admin/increment/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
