import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import Header from '../common/header-simple';

// ----------------------------------------------------------------------

export default function PublicLayout({ children, isInner }) {
  return (
    <>
      {!isInner && <Header />}

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            // maxWidth: 400,
            // minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.node,
  isInner: PropTypes.bool,
};
