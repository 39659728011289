import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createProduct,
  getAllProduct,
  deleteProduct,
  archiveProduct,
  restoreProduct,
  checkSapIdValid,
  getProductCounts,
  getProductDetails,
  getAllProductExcel,
  changeProductStatus,
  updateProductDetails,
  checkProductNameValid,
  getAllProductBySearch,
  createVariantByProduct,
  getAllVariantByProduct,
} from '../api/product';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    loading: false,
    statusLoading: false,
    delLoading: false,
    excelLoading: false,
    error: {},
    products: {},
    excelProducts: {},
    productCounts: {},
    productDetails: {},
    isSapIdValid: {},
    sapLoading: false,
    isProductValid: {},
    prodNameLoading: false,
    productBySearch: {},

    variantLoading: false,
    productVariants: {},
    createdProductVariants: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
    clearProductDetailsState: (state) => {
      state.productDetails = {};
    },
  },
  extraReducers: {
    // create product details
    [createProduct.pending]: (state) => {
      state.loading = true;
    },
    [createProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.productDetails = action.payload;
      state.error = {};
    },

    [createProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // check sap-id
    [checkSapIdValid.pending]: (state) => {
      state.sapLoading = true;
    },
    [checkSapIdValid.fulfilled]: (state, action) => {
      state.sapLoading = false;
      state.isSapIdValid = action.payload;
      state.error = {};
    },
    [checkSapIdValid.rejected]: (state, action) => {
      state.sapLoading = false;
      state.error = action.error;
    },

    // check product name
    [checkProductNameValid.pending]: (state) => {
      state.prodNameLoading = true;
    },
    [checkProductNameValid.fulfilled]: (state, action) => {
      state.prodNameLoading = false;
      state.isProductValid = action.payload;
      state.error = {};
    },
    [checkProductNameValid.rejected]: (state, action) => {
      state.prodNameLoading = false;
      state.error = action.error;
    },

    //  category status change
    [changeProductStatus.pending]: (state) => {
      state.statusLoading = true;
    },

    [changeProductStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.products;
      const modifiedProductList = {
        ...jsonState,
        products: jsonState?.products?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedProductList2 = {
        ...jsonState,
        products: jsonState?.products?.filter(
          (item) => item?.uid !== action.payload?.response?.uid
        ),
      };

      const jsonStateVariant = covertToJSON(state)?.productVariants;

      const modifiedProductVariantList = {
        ...jsonState,
        product_variants: jsonStateVariant?.product_variants?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };

      state.variantLoading = false;
      state.productVariants = modifiedProductVariantList;

      state.statusLoading = false;
      state.products =
        action?.payload?.tab === 'inactive' || action?.payload?.tab === 'active'
          ? modifiedProductList2
          : modifiedProductList;
      state.error = {};
    },
    [changeProductStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // get all product
    [getAllProduct.pending]: (state) => {
      state.loading = true;
    },
    [getAllProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.products = action.payload;
      state.error = {};
    },
    [getAllProduct.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all product by search
    [getAllProductBySearch.pending]: (state) => {
      state.loading = true;
    },
    [getAllProductBySearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.productBySearch = action.payload;
      state.error = {};
    },
    [getAllProductBySearch.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all excell product
    [getAllProductExcel.pending]: (state) => {
      state.excelLoading = true;
    },
    [getAllProductExcel.fulfilled]: (state, action) => {
      state.excelLoading = false;
      state.excelProducts = action.payload;
      state.error = {};
    },
    [getAllProductExcel.rejected]: (state, action) => {
      state.excelLoading = false;
      state.error = action.error;
    },
    // get product count
    [getProductCounts.pending]: (state) => {
      // state.loading = true;
    },
    [getProductCounts.fulfilled]: (state, action) => {
      // state.loading = false;
      state.productCounts = action.payload;
      state.error = {};
    },
    [getProductCounts.rejected]: (state, action) => {
      // state.loading = false;
      state.error = action.error;
    },

    // get single product details
    [getProductDetails.pending]: (state) => {
      state.loading = true;
    },
    [getProductDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.productDetails = action.payload;
      state.error = {};
    },
    [getProductDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update product details
    [updateProductDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateProductDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.products;
      const modifiedProductList = {
        ...jsonState,
        products: jsonState.products?.map((i) =>
          i.uid === action.payload.uid ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.productDetails = action.payload;
      state.products = modifiedProductList;
      state.error = {};
    },

    [updateProductDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // archive product
    [archiveProduct.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveProduct.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.products;
      const modifiedProductList = {
        ...jsonState,
        products: jsonState.products?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.products = modifiedProductList;
      state.error = {};
    },
    [archiveProduct.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // delete product
    [deleteProduct.pending]: (state) => {
      state.delLoading = true;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.products;
      const modifiedProductList = {
        ...jsonState,
        products: jsonState.products?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.products = modifiedProductList;
      state.error = {};

      const jsonStateVariant = covertToJSON(state)?.productVariants;

      const modifiedProductVariantList = {
        ...jsonState,
        product_variants: jsonStateVariant?.product_variants?.filter(
          (item) => item?.uid !== action.payload
        ),
      };

      state.variantLoading = false;
      state.productVariants = modifiedProductVariantList;
    },
    [deleteProduct.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    // restore product
    [restoreProduct.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreProduct.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.products;
      const modifiedProductList = {
        ...jsonState,
        products: jsonState.products?.filter((product) => product.uid !== action.payload),
      };

      state.delLoading = false;
      state.products = modifiedProductList;
      state.error = {};
    },
    [restoreProduct.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },

    [createVariantByProduct.pending]: (state) => {
      state.variantLoading = true;
    },
    [createVariantByProduct.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.productVariants;

      const modifiedProductList = {
        ...jsonState,
        product_variants: [...(jsonState?.product_variants || []), action.payload],
      };

      state.variantLoading = false;
      state.createdProductVariants = action.payload;
      state.productVariants = modifiedProductList;
      state.error = {};
    },

    [createVariantByProduct.rejected]: (state, action) => {
      state.variantLoading = false;
      state.error = action.error;
    },
    [getAllVariantByProduct.pending]: (state) => {
      state.variantLoading = true;
    },
    [getAllVariantByProduct.fulfilled]: (state, action) => {
      state.variantLoading = false;
      state.productVariants = action.payload;
      state.error = {};
    },

    [getAllVariantByProduct.rejected]: (state, action) => {
      state.variantLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError, clearProductDetailsState } = productSlice.actions;

export default productSlice.reducer;
