import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createBlog,
  deleteBlog,
  archiveBlog,
  getAllBlogs,
  restoreBlog,
  getBlogCounts,
  getBlogDetails,
  updateBlogStatus,
  updateBlogDetails,
} from '../api/blogs';

export const blogSlice = createSlice({
  name: 'blogs',
  initialState: {
    loading: false,
    error: {},
    blogs: {},
    blogCounts: {},
    blogDetails: {},
    statusLoading: false,
    delLoading: false,
  },
  reducers: {
    clearBlogError: (state) => {
      state.error = true;
    },
    clearBlogState: (state) => {
      state.blogDetails = {};
    },
  },
  extraReducers: {
    // create
    [createBlog.pending]: (state) => {
      state.loading = true;
    },
    [createBlog.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subcategories;
      const newDistrictsList = jsonState?.subcategories;

      const modifiedList = {
        ...jsonState,
        subcategories: Array.isArray(newDistrictsList)
          ? [...newDistrictsList, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.blogs = action.payload;
      state.blogs = modifiedList;
      state.error = {};
    },
    [createBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // all list
    [getAllBlogs.pending]: (state) => {
      state.loading = true;
    },
    [getAllBlogs.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
      state.error = {};
    },
    [getAllBlogs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get blog count
    [getBlogCounts.pending]: (state) => {
      state.loading = true;
    },
    [getBlogCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogCounts = action.payload;
      state.error = {};
    },
    [getBlogCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // singleView
    [getBlogDetails.pending]: (state) => {
      state.loading = true;
    },
    [getBlogDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogDetails = action.payload;
      state.error = {};
    },
    [getBlogDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update
    [updateBlogDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateProductGroupDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.ProductLabelDetails = action.payload;
    //   state.error = {};
    // },
    [updateBlogDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogDetails = action.payload;
      state.blogs = action.payload;
      state.error = {};
    },

    [updateBlogDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // update status
    [updateBlogStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [updateBlogStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.blogs;
      const modifiedReviewList = {
        ...jsonState,
        blogs: jsonState?.blogs?.map((item) =>
          item?.uid === action.payload?.uid
            ? { ...item, is_active: action.payload?.is_active }
            : item
        ),
      };
      state.statusLoading = false;
      state.blogs = modifiedReviewList;
      state.error = {};
    },

    [updateBlogStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },

    // archive
    [archiveBlog.pending]: (state) => {
      state.delLoading = true;
    },
    [archiveBlog.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.blogs;
      const modifiedReviewList = {
        ...jsonState,
        blogs: jsonState.blogs?.filter((review) => review.uid !== action.payload),
      };

      state.delLoading = false;
      state.blogs = modifiedReviewList;
      state.error = {};
    },
    [archiveBlog.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // restore

    [restoreBlog.pending]: (state) => {
      state.delLoading = true;
    },
    [restoreBlog.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.blogs;
      const modifiedReviewList = {
        ...jsonState,
        blogs: jsonState.blogs?.filter((review) => review.uid !== action.payload),
      };

      state.delLoading = false;
      state.blogs = modifiedReviewList;
      state.error = {};
    },
    [restoreBlog.rejected]: (state, action) => {
      state.delLoading = false;
      state.error = action.error;
    },
    // delete
    [deleteBlog.pending]: (state) => {
      state.loading = true;
    },
    [deleteBlog.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.blogs;
      const modifiedList = {
        ...jsonState,
        blogs: jsonState.blogs?.filter((review) => review.uid !== action.payload),
      };
      state.loading = false;
      state.blogs = modifiedList;
      state.error = {};
    },
    [deleteBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearBlogError: clearProductGroupError, clearBlogState } = blogSlice.actions;

export default blogSlice.reducer;
