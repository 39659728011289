// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  LMS: '/academy',
  INVENTORY: `/dashboard/inventory`,
  SETTINGS: `/dashboard/site-settings`,
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
    privacy_policy: '/privacy-policy',
    academy_landing: ROOTS.LMS,
    course_view: (id) => `${ROOTS.LMS}/course/${id}`,
    release_note: `/release-note`,
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login/admin`,
    logout: `${ROOTS.AUTH}/logout`,

    create_password: (type, token) => `${ROOTS.AUTH}/create-password/:${type}/:${token}`,
    forgot_password_email: (type, token) => `${ROOTS.AUTH}/password/reset/:${type}/:${token}`,
    forgot_password: `${ROOTS.AUTH}/forgot-password`,
    password_verification: (type, token) => `${ROOTS.AUTH}/invitation/:${type}/:${token}`,

    course_content_view: (id) => `${ROOTS.LMS}/course/content/${id}`,
    exams: `${ROOTS.LMS}/exams`,
    exams_register: (id) => `${ROOTS.LMS}/exams/register/${id}`,
    exams_view: (studentExam, exam) => `${ROOTS.LMS}/exams/${studentExam}/${exam}`,
    exams_over: (id) => `${ROOTS.LMS}/exams/${id}/over`,
    student_profile: `${ROOTS.LMS}/student-profile`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    onBoarding: '/on-boarding',
    profile: `${ROOTS.DASHBOARD}/profile`,

    orders: `${ROOTS.DASHBOARD}/orders`,
    order_singleView: (id) => `${ROOTS.DASHBOARD}/order/details/${id}`,
    settings: {
      root: `${ROOTS.DASHBOARD}/site-settings`,
      payment: `${ROOTS.DASHBOARD}/site-settings/payment`,
      preference: {
        header: `${ROOTS.DASHBOARD}/site-settings/preference/header`,
        footer: `${ROOTS.DASHBOARD}/site-settings/preference/footer`,
      },
      finance: `${ROOTS.SETTINGS}/finance`,
      banners: `${ROOTS.SETTINGS}/banners`,

      groups: `${ROOTS.SETTINGS}/groups`,
      group_create: `${ROOTS.SETTINGS}/group/create`,
      group_update: (id) => `${ROOTS.SETTINGS}/group/update/${id}`,

      pickupLocation: `${ROOTS.SETTINGS}/pickup-location`,
      pickupLocation_crate: `${ROOTS.SETTINGS}/pickup-location/create`,
      pickupLocation_update: (id) => `${ROOTS.SETTINGS}/pickup-location/update/${id}`,

      pages: `${ROOTS.SETTINGS}/pages`,
      page_crate: `${ROOTS.SETTINGS}/page/create`,
      page_update: (id) => `${ROOTS.SETTINGS}/page/update/${id}`,

      coupon: `${ROOTS.SETTINGS}/coupon`,
      coupon_crud: `${ROOTS.SETTINGS}/coupon/create`,
      coupon_update: (id) => `${ROOTS.SETTINGS}/coupon/update/${id}`,

      blogs: `${ROOTS.SETTINGS}/blogs`,
      blog_create: `${ROOTS.SETTINGS}/blog/create`,
      blog_update: (id) => `${ROOTS.SETTINGS}/blog/update/${id}`,
    },

    inventory: {
      root: `${ROOTS.DASHBOARD}/inventory`,

      category: `${ROOTS.INVENTORY}/category`,
      category_crud: `${ROOTS.INVENTORY}/category/create`,
      category_update: (id) => `${ROOTS.INVENTORY}/category/update/${id}`,

      brand: `${ROOTS.INVENTORY}/brand`,
      brand_crud: `${ROOTS.INVENTORY}/brand/create`,
      brand_update: (id) => `${ROOTS.INVENTORY}/brand/update/${id}`,

      collection: `${ROOTS.INVENTORY}/collection`,
      collection_crud: `${ROOTS.INVENTORY}/collection/create`,
      collection_update: (id) => `${ROOTS.INVENTORY}/collection/update/${id}`,

      user: `${ROOTS.INVENTORY}/user`,
      user_view: (id) => `${ROOTS.INVENTORY}/user/view/${id}`,

      product_attributes: `${ROOTS.INVENTORY}/product-attributes`,

      // product_variation: `${ROOTS.INVENTORY}/product-variation`,
      product_variation_crud: `${ROOTS.INVENTORY}/product-variation/create`,
      product_variation_update: (id) => `${ROOTS.INVENTORY}/product-variation/update/${id}`,

      // product_label: `${ROOTS.INVENTORY}/product-label`,
      product_label_crud: `${ROOTS.INVENTORY}/product-label/create`,
      product_label_update: (id) => `${ROOTS.INVENTORY}/product-label/update/${id}`,

      product_group: `${ROOTS.INVENTORY}/product-group`,
      product_group_crud: `${ROOTS.INVENTORY}/product-group/create`,
      product_group_update: (id) => `${ROOTS.INVENTORY}/product-group/update/${id}`,

      currency: `${ROOTS.INVENTORY}/currency`,
      special_sale: `${ROOTS.INVENTORY}/special-sale`,
      special_sale_crud: `${ROOTS.INVENTORY}/special-sale/create`,
      special_sale_view: (id) => `${ROOTS.INVENTORY}/special-sale/update/${id}`,

      banners_create: `${ROOTS.INVENTORY}/banners/create`,
    },
    product_update: (id) => `${ROOTS.DASHBOARD}/product/update/${id}`,

    reviews: `${ROOTS.DASHBOARD}/reviews`,
    review_create: `${ROOTS.DASHBOARD}/review/create`,
    review_update: (id) => `${ROOTS.DASHBOARD}/review/update/${id}`,

    stock_and_offer: `${ROOTS.DASHBOARD}/stock-offer`,
    collections: `${ROOTS.DASHBOARD}/collections`,
    coupons: `${ROOTS.DASHBOARD}/coupons`,
    users: `${ROOTS.DASHBOARD}/users`,
    revenue: `${ROOTS.DASHBOARD}/revenue`,
    performance: `${ROOTS.DASHBOARD}/performance`,

    country: `${ROOTS.DASHBOARD}/country`,
    district: `${ROOTS.DASHBOARD}/district`,
    state: `${ROOTS.DASHBOARD}/state`,
    city: `${ROOTS.DASHBOARD}/city`,

    // users: `${ROOTS.DASHBOARD}/users`,
    user_create: `${ROOTS.DASHBOARD}/user/create`,
    user_view: (id) => `${ROOTS.DASHBOARD}/user/${id}`,

    category: `${ROOTS.DASHBOARD}/category`,
    marketPrice: `${ROOTS.DASHBOARD}/market-price`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
};
