import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createUser,
  getAllUsers,
  getUserCounts,
  getUserDetails,
  getUserWishlist,
  changeUserStatus,
  updateUserDetails,
  getAllUserCartItems,
  getAllUserOrderItems,
  getUserShippingAddress,
  getAllUserFavoriteItems,
  getAllUserRecentlyViewedItems,
} from '../api/user';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    error: {},
    users: {},
    userDetails: {},
    userCounts: {},
    statusLoading: false,
    userCartItems: {},
    userFavoriteItems: {},
    userRecentlyViewedItems: {},
    userOrderItems: {},
    userShippingAddress: {},
    userWishlist: {},
  },
  reducers: {
    clearUserError: (state) => {
      state.error = true;
    },
    clearUserDetailsState: (state) => {
      state.userDetails = {};
    },
  },
  extraReducers: {
    // get all Users
    [getAllUsers.pending]: (state) => {
      state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = {};
    },
    [getAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // user count
    [getUserCounts.pending]: (state) => {
      state.loading = true;
    },
    [getUserCounts.fulfilled]: (state, action) => {
      state.loading = false;
      state.userCounts = action.payload;
      state.error = {};
    },
    [getUserCounts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    //  user status change
    [changeUserStatus.pending]: (state) => {
      state.statusLoading = true;
    },
    [changeUserStatus.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.users;
      const modifiedUserList = {
        ...jsonState,
        users: jsonState?.users?.map((item) =>
          item?.uid === action.payload?.response?.uid
            ? { ...item, is_active: action.payload?.response?.is_active }
            : item
        ),
      };
      const modifiedUserList2 = {
        ...jsonState,
        users: jsonState?.users?.filter(
          (item) => item?.is_active === action.payload?.response?.is_active
        ),
      };

      state.statusLoading = false;
      state.users = action?.payload?.tab === 'inactive' ? modifiedUserList2 : modifiedUserList;
      state.error = {};
    },

    [changeUserStatus.rejected]: (state, action) => {
      state.statusLoading = false;
      state.error = action.error;
    },
    // create user

    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.users;
      const newUserList = jsonState?.users;

      const modifiedUserList = {
        ...jsonState,
        users: Array.isArray(newUserList) ? [action.payload, ...newUserList] : [action.payload],
      };
      state.loading = false;
      state.userDetails = action.payload;
      state.users = modifiedUserList;
      state.error = {};
    },
    [createUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single user details
    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
      state.error = {};
    },
    [getUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update user details
    [updateUserDetails.pending]: (state) => {
      state.loading = true;
    },

    [updateUserDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.users;
      const modifiedUserList = {
        ...jsonState,
        users: jsonState.users?.map((i) => (i.uid === action.payload.uid ? action?.payload : i)),
      };

      state.loading = false;
      state.userDetails = action.payload;
      state.users = modifiedUserList;
      state.error = {};
    },
    [updateUserDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all User cart items
    [getAllUserCartItems.pending]: (state) => {
      state.loading = true;
    },
    [getAllUserCartItems.fulfilled]: (state, action) => {
      state.loading = false;
      state.userCartItems = action.payload;
      state.error = {};
    },
    [getAllUserCartItems.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all User favorite items
    [getAllUserFavoriteItems.pending]: (state) => {
      state.loading = true;
    },
    [getAllUserFavoriteItems.fulfilled]: (state, action) => {
      state.loading = false;
      state.userFavoriteItems = action.payload;
      state.error = {};
    },
    [getAllUserFavoriteItems.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all User recently viewed items
    [getAllUserRecentlyViewedItems.pending]: (state) => {
      state.loading = true;
    },
    [getAllUserRecentlyViewedItems.fulfilled]: (state, action) => {
      state.loading = false;
      state.userRecentlyViewedItems = action.payload;
      state.error = {};
    },
    [getAllUserRecentlyViewedItems.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all User recently viewed items
    [getAllUserOrderItems.pending]: (state) => {
      state.loading = true;
    },
    [getAllUserOrderItems.fulfilled]: (state, action) => {
      state.loading = false;
      state.userOrderItems = action.payload;
      state.error = {};
    },
    [getAllUserOrderItems.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getUserShippingAddress.pending]: (state) => {
      state.loading = true;
    },
    [getUserShippingAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.userShippingAddress = action.payload;
      state.error = {};
    },
    [getUserShippingAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getUserWishlist.pending]: (state) => {
      state.loading = true;
    },
    [getUserWishlist.fulfilled]: (state, action) => {
      state.loading = false;
      state.userWishlist = action.payload;
      state.error = {};
    },
    [getUserWishlist.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearUserError, clearUserDetailsState } = userSlice.actions;

export default userSlice.reducer;
