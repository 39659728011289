import { React } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardMedia,
  Typography,
} from '@mui/material';

import useResource from 'src/routes/hooks/useCloudinaryImage';

import { fDate } from 'src/utils/format-time';

import NoImg from 'src/assets/other/ImgNull.jpg';
import { table } from 'src/theme/overrides/components/table';

import Label from 'src/components/label';
import TextMaxLine from 'src/components/text-max-line';

function SingleViewPDFDownload({ data, componentRef }) {
  const handleOrderStatus = () => {
    switch (data?.order_status) {
      case 'PENDING':
        return {
          color: 'warning',
          label: 'Pending',
        };
      case 'PROCESSING':
        return {
          color: 'primary',
          label: 'Processing',
        };
      case 'PICKUP_SCHEDULED':
        return {
          color: 'info',
          label: 'Pickup Scheduled',
        };
      case 'SHIPPED':
        return {
          color: 'info',
          label: 'Shipped',
        };
      case 'DELIVERED':
        return {
          color: 'info',
          label: 'Delivered',
        };
      case 'CANCELLED':
        return {
          color: 'error',
          label: 'Cancelled',
        };
      case 'CANCEL_INITIATED':
        return {
          color: 'error',
          label: 'Cancel Initiated',
        };
      case 'RETURNED':
        return {
          color: 'error',
          label: 'Returned',
        };
      case 'RETURN_INITIATED':
        return {
          color: 'error',
          label: 'Return Initiated',
        };
      default:
        return {
          color: 'default',
          label: '',
        };
    }
  };
  return (
    <Stack ref={componentRef}>
      <Card
        sx={{
          p: 3,
        }}
      >
        <Card sx={{ py: 2, px: 3 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">User</Typography>
          </Stack>

          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {data?.user?.name}
          </Typography>
          <Typography variant="body2">
            Contact No : +{data?.user?.country_code} {data?.user?.phone}{' '}
          </Typography>
          <Typography variant="body2">Joined on : {fDate(data?.user?.createdAt)}</Typography>
          <Grid container spacing={3} pt={3}>
            <Grid item md={5} lg={4}>
              <Box>
                <Typography variant="h6">Order</Typography>
                <Typography variant="body2" py={0.5}>
                  Order id :&nbsp;&nbsp; {data?.order_id}
                </Typography>
                <Typography variant="body2">
                  Order Status : &nbsp;&nbsp;
                  <Label variant="soft" color={handleOrderStatus()?.color}>
                    {handleOrderStatus()?.label}
                  </Label>
                </Typography>
                <Typography variant="body2" py={0.5}>
                  {' '}
                  Order Date :&nbsp;&nbsp; {fDate(data?.createdAt)}
                </Typography>
                <Typography variant="body2">
                  Invoice Date :&nbsp;&nbsp; {fDate(data?.createdAt)}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} lg={4}>
              <Box>
                <Typography variant="h6">Payment</Typography>
                {data?.createdAt && (
                  <Typography variant="body2" py={0.5}>
                    Invoice Date: {fDate(data?.createdAt)}
                  </Typography>
                )}
                {data?.payment_type && (
                  <Typography variant="body2"> Payment Type: {data?.payment_type}</Typography>
                )}
                {data?.transaction_id && (
                  <Typography variant="body2" py={0.5}>
                    Transaction Id: {data?.transaction_id}{' '}
                  </Typography>
                )}
              </Box>
            </Grid>
            {data?.shipping_address?.tag ? (
              <Grid item md={4} lg={4}>
                <Box>
                  <Typography variant="h6">Shipping</Typography>
                  <Typography variant="body2" pt={0.5} sx={{ fontWeight: 'bold' }}>
                    {data?.shipping_address?.name}
                  </Typography>
                  <Typography variant="body2">
                    {data?.shipping_address?.address}
                    {data?.shipping_address?.address_line_1}
                  </Typography>
                  <Typography variant="body2">
                    {data?.shipping_address?.district?.title?.english}
                  </Typography>
                  <Typography variant="body2">
                    {data?.shipping_address?.state?.title?.english},
                    {data?.shipping_address?.country?.title?.english}
                  </Typography>
                  <Typography variant="body2">
                    Contact no: +{data?.shipping_address?.country_code}{' '}
                    {data?.shipping_address?.phone}
                  </Typography>
                </Box>
              </Grid>
            ) : (
              <Grid item md={4} lg={4}>
                <Box>
                  <Typography variant="h6">Self Pickup</Typography>
                  <Typography variant="body2" pt={0.5} sx={{ fontWeight: 'bold' }}>
                    {data?.self_pickup?.name}
                  </Typography>
                  <Typography variant="body2">
                    {data?.self_pickup?.address}
                    {data?.self_pickup?.address_line_1}
                  </Typography>
                  <Typography variant="body2">
                    {data?.self_pickup?.district?.title?.english}
                  </Typography>
                  <Typography variant="body2">
                    {data?.self_pickup?.state?.title?.english},
                    {data?.self_pickup?.country?.title?.english}
                  </Typography>
                  <Typography variant="body2">
                    Contact no: +{data?.self_pickup?.country_code} {data?.self_pickup?.phone}{' '}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Card>

        <Grid container spacing={2} mt={2}>
          <Grid item md={9} lg={9}>
            <Card sx={{}}>
              <Table size={table.dense ? 'small' : 'medium'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.order_items?.map((item, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <Stack direction="row" alignItems="center" justifyContent="start">
                          <CardMedia
                            component="img"
                            src={
                              item?.product?.images
                                ? // eslint-disable-next-line react-hooks/rules-of-hooks
                                  useResource(item?.product?.images[0]?.url, 'w_500')
                                : NoImg
                            }
                            height="50px"
                            style={{
                              objectFit: 'contain',
                              width: 50,
                            }}
                          />
                          <Stack>
                            <Button>
                              <TextMaxLine line={2} sx={{ maxWidth: 350, textAlign: 'start' }}>
                                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                                  {item?.product?.name?.english}
                                </Typography>
                              </TextMaxLine>
                            </Button>
                            <Typography color="#919EAB" variant="caption" pl={1}>
                              {item?.product?.sap_id}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell>{item?.quantity}</TableCell>
                      <TableCell>{item?.product_price}</TableCell>
                      <TableCell>{item?.product_item_price}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </Grid>
          <Grid item md={3} lg={3}>
            <Card sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Shipping
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                {/* Left Column */}
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    Item Total
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0 }}>
                    Shipping
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0, color: 'rgba(34, 197, 94, 1)' }}>
                    Discount
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0, color: 'rgba(34, 197, 94, 1)' }}>
                    Coupon Discount
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0 }}>
                    Tax
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    Gross Total
                  </Typography>
                </Stack>

                {/* Right Column */}
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    OMR {data?.items_price}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0 }}>
                    OMR {data?.total_shipping_price}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0, color: 'rgba(34, 197, 94, 1)' }}>
                    OMR {data?.total_discount}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0, color: 'rgba(34, 197, 94, 1)' }}>
                    OMR {data?.total_coupon_discount}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0 }}>
                    OMR {data?.tax_value}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    OMR {data?.total_price}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
}

export default SingleViewPDFDownload;

SingleViewPDFDownload.propTypes = {
  data: PropTypes.object,
  componentRef: PropTypes.any,
};
