import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
// import Avatar from '@mui/material/Avatar';
import { Box, Stack, Switch, Tooltip, IconButton, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { fDate } from 'src/utils/format-time';

import { deleteBlog, archiveBlog, restoreBlog } from 'src/server/api/blogs';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
// ----------------------------------------------------------------------

export default function BlogsTableRow({
  row,
  selected,
  onEditRow,
  onDeleteRow,
  tab,
  handleChangeStatus,
  dispatch,
}) {
  const { title, is_active, uid, createdAt } = row;

  const confirm = useBoolean();
  const popover = usePopover();

  const handleClose = () => {
    confirm.onFalse();
  };

  const handleArchiveReviewClick = () => {
    const credentials = {
      dispatch,
      blogId: uid,
      handleClose,
    };
    dispatch(archiveBlog(credentials));
  };

  const handleDeleteReviewConfirmClick = () => {
    const credentials = {
      dispatch,
      blogId: uid,
      handleClose,
    };
    dispatch(deleteBlog(credentials));
  };

  const handleDeleteReview = () => {
    confirm.onTrue();
    popover.onClose();
  };

  const handleUnarchiveProduct = () => {
    const credentials = {
      dispatch,
      blogId: uid,
      handleClose,
    };
    dispatch(restoreBlog(credentials));
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
            {fDate(createdAt) || '---'}
          </Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', maxWidth: 150 }}>
          <TextMaxLine line={2}>{title?.english || '---'}</TextMaxLine>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Switch
              color="success"
              checked={is_active}
              onChange={(e) => handleChangeStatus(uid, e.target.checked)}
            />{' '}
            <Typography variant="caption">{is_active === true ? 'Published' : 'Draft'}</Typography>
          </Stack>
        </TableCell>{' '}
        <TableCell align="center" sx={{ px: 1 }}>
          <Box
            columnGap={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              color: 'text.secondary',
              typography: 'caption',
              mb: 2,
              border: '1px solid #919eab24',
              padding: 0.6,
              borderRadius: '14px',
              width: 'fit-content',
            }}
          >
            <Tooltip title="Edit Blog">
              <Stack direction="row" alignItems="center">
                <IconButton
                  color="info"
                  onClick={() => onEditRow(uid)}
                  sx={{ borderRadius: '6px' }}
                >
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Stack>
            </Tooltip>

            <Stack direction="row" alignItems="center">
              {tab === 'is_deleted' ? (
                <>
                  <Tooltip title="Restore Blog">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="default"
                      onClick={handleUnarchiveProduct}
                    >
                      <UnarchiveRoundedIcon color="info" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Blog">
                    <IconButton
                      sx={{
                        borderRadius: '6px',
                      }}
                      color="error"
                      onClick={handleDeleteReview}
                    >
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Archive Blog">
                  <IconButton
                    sx={{
                      borderRadius: '6px',
                    }}
                    color="default"
                    onClick={handleArchiveReviewClick}
                  >
                    <ArchiveRoundedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Box>
        </TableCell>
      </TableRow>

      {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            onDeleteRow();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover> */}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={handleDeleteReviewConfirmClick}>
            Delete
          </Button>
        }
      />
    </>
  );
}

BlogsTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  handleChangeStatus: PropTypes.func,
  tab: PropTypes.string,
  dispatch: PropTypes.func,
};
