import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createCoupon = createAsyncThunk(
  'coupon/create',
  async ({ state, dispatch, router }) => {
    try {
      const URL = `/coupon/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        router.push(paths.dashboard.settings.coupon);
        dispatch(activeSnack({ type: 'success', message: 'Coupon Created' }));
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCouponDetails = createAsyncThunk(
  'coupon/update',
  async ({ state, couponId, dispatch }) => {
    try {
      const URL = `/coupon/admin/${couponId}`;
      const response = await put(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Coupon Updated' }));
        dispatch(getCouponDetails({ couponId }));
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );

      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCoupon = createAsyncThunk(
  'coupon/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `/coupon/admin/summary/all?page=${page && page}&search=${search || ''}&limit=${
          limit || 20
        }`,
        state
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getCouponCounts = createAsyncThunk('coupon/count', async ({ dispatch, state }) => {
  try {
    const response = await post(`/coupon/admin/count/all`, state);

    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getCouponDetails = createAsyncThunk(
  'coupon/single',
  async ({ couponId, dispatch }) => {
    try {
      const response = await get(`/coupon/admin/single/${couponId}`);
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const changeCouponStatus = createAsyncThunk(
  'coupon/status',
  async ({ state, couponId, dispatch, tab }) => {
    try {
      const URL = `/coupon/admin/status/active/${couponId}`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Status Updated Successfully' }));
        return { response, tab };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const checkCouponNameValid = createAsyncThunk(
  'coupon/check/coupon-name',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/coupon/admin/english-name/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const archiveCoupon = createAsyncThunk('coupon/archive', async ({ couponId, dispatch }) => {
  try {
    const response = await post(`/coupon/admin/delete/${couponId}`);
    if (response?.isSuccess) {
      dispatch(activeSnack({ type: 'success', message: 'Coupon Archived' }));
      return response?.uid;
    }
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const restoreCoupon = createAsyncThunk(
  'coupon/unarchive',
  async ({ couponId, dispatch }) => {
    try {
      const response = await post(`/coupon/admin/restore/${couponId}`);
      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Coupon Restored' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const deleteCoupon = createAsyncThunk(
  'coupon/delete',
  async ({ couponId, dispatch, handleClose }) => {
    try {
      const response = await del(`/coupon/admin/hard-delete/${couponId}`);
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(activeSnack({ type: 'success', message: 'Coupon Deleted Successfully' }));
        return response?.uid;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
// export const getAllRedeemedUserList = createAsyncThunk(
//   'redeemed-user/list',
//   async ({ page, search, dispatch, limit, state }) => {
//     try {
//       const response = await post(
//         `/coupon/admin/summary/all?page=${page && page}&search=${search || ''}&limit=${limit || 20}`,
//         state
//       );
//       if (response) {
//         return response;
//       }
//       return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
//     } catch (error) {
//       dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//       throw error?.response?.data?.message;
//     }
//   }
// );

export const getRedeemedUserList = createAsyncThunk(
  'redeemed-user/list',
  async ({ couponId, dispatch }) => {
    try {
      const response = await post(`/coupon/admin/users/${couponId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
