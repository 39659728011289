import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import React, { useState, useEffect, useCallback } from 'react';

import InfoIcon from '@mui/icons-material/Info';
// @mui
import RotateRightIcon from '@mui/icons-material/RotateRight';
import {
  Box,
  Stack,
  Button,
  Slider,
  Dialog,
  Switch,
  Tooltip,
  Divider,
  FormGroup,
  IconButton,
  Typography,
  DialogContent,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';

import Iconify from 'src/components/iconify';

// components
const aspectRatios = [
  {
    ratio: 1,
    label: '1:1',
    icon: <Iconify icon="ph:square-thin" />,
    helpText: '1:1 for square images, common on Instagram',
  },
  {
    ratio: 3 / 2,
    label: '3:2',
    icon: <Iconify icon="arcticons:rectangle-horizontal" />,
    helpText: '3:2 for landscape photography',
  },
  {
    ratio: 2 / 3,
    label: '2:3',
    icon: <Iconify icon="arcticons:rectangle-vertical" />,
    helpText: '2:3 for portrait photography',
  },

  {
    ratio: 16 / 9,
    label: '16:9',
    icon: (
      <Box component="div" sx={{ transform: 'rotate(90deg)', display: 'flex' }}>
        {LucideRectangleVertical()}
      </Box>
    ),
    helpText: '16:9 for widescreen videos (HD/4K)',
  },
  {
    ratio: 9 / 16,
    label: '9:16',
    icon: LucideRectangleVertical(),
    helpText: '9:16 for vertical videos (Instagram, TikTok)',
  },
  {
    ratio: 5 / 4,
    label: '5:4',
    icon: <Iconify icon="fluent:rectangle-landscape-32-light" />,
    helpText: '5:4 for older medium-format photography',
  },
  {
    ratio: 21 / 9,
    label: '21:9',
    icon: LucideRectangleHorizontal(),
    helpText: '21:9 for cinema widescreen and ultra-wide screens',
  },
  {
    ratio: 4 / 3,
    label: '4:3',
    icon: <Iconify icon="material-symbols-light:aspect-ratio-outline" />,
    helpText: '4:3 for traditional photography (used in digital cameras)',
  },
  {
    ratio: 16 / 10,
    label: '16:10',
    icon: <Iconify icon="bi:aspect-ratio" />,
    helpText: '16:10 for widescreen monitors and laptops',
  },
];

ImageCropPopup.propTypes = {
  uploadLoad: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  uploadingImage: PropTypes.func,
  image: PropTypes.object,
};

export default function ImageCropPopup({ open, onClose, image, uploadingImage, uploadLoad }) {
  const [onCroppingImg, setOnCroppingImg] = useState(image);

  const [loading, setLoading] = useState({
    applyLoad: false,
    saveLoad: false,
  });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspect, setAspect] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [readyToSave, setReadyToSave] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPx) => {
    setCroppedAreaPixels(croppedAreaPx);
  }, []);

  const cropImageNow = useCallback(async () => {
    setLoading({ ...loading, applyLoad: true });
    try {
      const croppedImage = await getCroppedImg(image.url, croppedAreaPixels, rotation, zoom);

      setOnCroppingImg({
        ...image,
        url: croppedImage,
      });
      setRotation(0);
      setZoom(1);
      // setCrop({ x: 0, y: 0 });
      // setAspect(1);

      setTimeout(() => {
        setReadyToSave(true);
      }, 500);
      setLoading({ ...loading, applyLoad: false });
    } catch (e) {
      console.error(e);
      setLoading({ ...loading, applyLoad: false });
    }
  }, [croppedAreaPixels, image, loading, rotation, zoom]);

  const handleRotationChange = () => {
    setRotation((pre) => (pre >= 360 ? 0 : pre + 90));
  };

  const handleAspectRatioChange = (ratio) => {
    setAspect(ratio);
  };

  const handleResetChanges = () => {
    setCrop({ x: 0, y: 0 });
    setRotation(0);
    setZoom(1);
    setAspect(1);
  };

  useEffect(() => {
    setReadyToSave(false);
  }, [crop, zoom, rotation, aspect]);

  const [replace, setReplace] = useState(true);

  const handleSaveEditedImage = () => {
    uploadingImage(onCroppingImg, 'base64', replace ? 'replace' : 'not_replace');
  };

  const handleClose = () => {
    onClose();
    handleResetChanges();
    setOnCroppingImg();
  };

  useEffect(() => {
    setOnCroppingImg(image);
  }, [image]);

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent sx={{ minWidth: '550px', p: 2 }}>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <IconButton
                onClick={() => {
                  handleResetChanges();
                  setOnCroppingImg(image);
                }}
                sx={{ borderRadius: '6px' }}
              >
                <Iconify icon="quill:discard" sx={{ height: '12px' }} />
                <Typography variant="caption">discard changes</Typography>
              </IconButton>
              <Tooltip title="rotate image">
                <IconButton onClick={() => handleRotationChange()}>
                  <RotateRightIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>

            <Box sx={{ position: 'relative', width: '100%', height: 300 }}>
              <Cropper
                image={onCroppingImg?.url}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={aspect}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                showGrid
              />
            </Box>
            <Typography variant="body2" textAlign="center" mt={1}>
              {rotation}°
            </Typography>

            <Box sx={{ mt: 1 }}>
              <Typography mb={0} gutterBottom variant="subtitle2">
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, newValue) => setZoom(newValue)}
              />
            </Box>

            <Stack direction="row" alignItems="center" spacing={2}>
              {aspectRatios?.map((item, key) => (
                <Stack spacing={0.4} alignItems="center" key={key}>
                  <Tooltip arrow title={<Box maxWidth="150px">{item?.helpText}</Box>}>
                    <Button
                      color="inherit"
                      size="small"
                      sx={{
                        backgroundColor: 'rgba(99, 115, 129, 0.08)',
                        width: 'fit-content',
                        minWidth: 'fit-content',
                        color: '#637481',
                        '&:hover': {
                          backgroundColor: 'rgba(99, 115, 129, 0.08)',
                        },
                        borderRadius: '6px',
                      }}
                      variant="contained"
                      onClick={() => handleAspectRatioChange(item?.ratio)}
                    >
                      {item?.icon}
                    </Button>
                  </Tooltip>
                  <Typography variant="caption">{item?.label}</Typography>
                </Stack>
              ))}
            </Stack>

            <Divider sx={{ borderStyle: 'dashed', mt: 4, mb: 2 }} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: readyToSave ? 'space-between' : 'end',
                gap: '10px',
              }}
            >
              <Stack direction="row" alignItems="center" spacing="10px">
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" sx={{ px: 3 }} onClick={cropImageNow}>
                  {loading.applyLoad ? <CircularProgress size={18} /> : 'Apply changes'}
                </Button>
              </Stack>

              {readyToSave && (
                <Stack spacing={0.5}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={replace}
                          onChange={(e) => setReplace(e.target.checked)}
                        />
                      }
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          Replace the original image
                          <Tooltip
                            title={
                              <>
                                You have two options for saving your edited image:
                                <br />
                                1. Replace the original image with the edited version,
                                <br /> 2. Save both the original image and the edited version
                                without removing the original
                              </>
                            }
                          >
                            <InfoIcon
                              fontSize="small"
                              className="hover-up"
                              sx={{ height: '16px' }}
                            />
                          </Tooltip>
                        </Box>
                      }
                    />
                  </FormGroup>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ px: 3 }}
                    onClick={handleSaveEditedImage}
                  >
                    {loading.saveLoad || uploadLoad ? <CircularProgress size={18} /> : 'Save'}
                  </Button>
                </Stack>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, zoom = 1) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const flip = { horizontal: false, vertical: false };

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width * zoom,
    image.height * zoom,
    rotation
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate((-image.width * zoom) / 2, (-image.height * zoom) / 2);
  // ctx.drawImage(image, 0, 0, image.width * zoom, image.height * zoom);
  ctx.drawImage(image, 0, 0);

  const croppedData = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(croppedData, 0, 0);

  return canvas.toDataURL('image/png');
}

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
}

function rotateSize(width, height, rotation) {
  const rotRad = (rotation * Math.PI) / 180;
  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

function LucideRectangleVertical(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect
        width={12}
        height={20}
        x={6}
        y={2}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        rx={2}
      />
    </svg>
  );
}

export function LucideRectangleHorizontal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect
        width={20}
        height={12}
        x={2}
        y={6}
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        rx={2}
      />
    </svg>
  );
}
