import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createReview,
  deleteReview,
  archiveReview,
  getAllReviews,
  restoreReview,
  getReviewCounts,
  getReviewDetails,
  updateReviewStatus,
  updateReviewDetails,
} from '../api/reviews';

export const reviewSlice = createSlice({
  name: 'reviews',
  initialState: {
    loading: false,
    error: {},
    reviews: {},
    reviewCounts: {},
    reviewDetails: {},
    statusLoading: false,
    delLoading: false,
  },
  reducers: {
    clearProductGroupError: (state) => {
      state.error = true;
    },
    clearReviewState: (state) => {
      state.reviewDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // create
      .addCase(createReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(createReview.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.subcategories;
        const newDistrictsList = jsonState?.subcategories;

        const modifiedList = {
          ...jsonState,
          subcategories: Array.isArray(newDistrictsList)
            ? [...newDistrictsList, action.payload]
            : [action.payload],
        };
        state.loading = false;
        state.reviews = action.payload;
        state.reviews = modifiedList;
        state.error = {};
      })
      .addCase(createReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // all list
      .addCase(getAllReviews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllReviews.fulfilled, (state, action) => {
        state.loading = false;
        state.reviews = action.payload;
        state.error = {};
      })
      .addCase(getAllReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // get group count
      .addCase(getReviewCounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReviewCounts.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewCounts = action.payload;
        state.error = {};
      })
      .addCase(getReviewCounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // single view
      .addCase(getReviewDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReviewDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewDetails = action.payload;
        state.error = {};
      })
      .addCase(getReviewDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // update
      .addCase(updateReviewDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateReviewDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewDetails = action.payload;
        state.reviews = action.payload;
        state.error = {};
      })
      .addCase(updateReviewDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      // update status
      .addCase(updateReviewStatus.pending, (state) => {
        state.statusLoading = true;
      })
      .addCase(updateReviewStatus.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.reviews;
        const modifiedReviewList = {
          ...jsonState,
          reviews: jsonState?.reviews?.map((item) =>
            item?.uid === action.payload?.uid
              ? { ...item, is_active: action.payload?.is_active }
              : item
          ),
        };
        state.statusLoading = false;
        state.reviews = modifiedReviewList;
        state.error = {};
      })
      .addCase(updateReviewStatus.rejected, (state, action) => {
        state.statusLoading = false;
        state.error = action.error;
      })

      // archive
      .addCase(archiveReview.pending, (state) => {
        state.delLoading = true;
      })
      .addCase(archiveReview.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.reviews;
        const modifiedReviewList = {
          ...jsonState,
          reviews: jsonState.reviews?.filter((review) => review.uid !== action.payload),
        };

        state.delLoading = false;
        state.reviews = modifiedReviewList;
        state.error = {};
      })
      .addCase(archiveReview.rejected, (state, action) => {
        state.delLoading = false;
        state.error = action.error;
      })

      // restore
      .addCase(restoreReview.pending, (state) => {
        state.delLoading = true;
      })
      .addCase(restoreReview.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.reviews;
        const modifiedReviewList = {
          ...jsonState,
          reviews: jsonState.reviews?.filter((review) => review.uid !== action.payload),
        };

        state.delLoading = false;
        state.reviews = modifiedReviewList;
        state.error = {};
      })
      .addCase(restoreReview.rejected, (state, action) => {
        state.delLoading = false;
        state.error = action.error;
      })

      // delete
      .addCase(deleteReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        const jsonState = covertToJSON(state)?.reviews;
        const modifiedList = {
          ...jsonState,
          reviews: jsonState.reviews?.filter((review) => review.uid !== action.payload),
        };
        state.loading = false;
        state.reviews = modifiedList;
        state.error = {};
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const { clearProductGroupError, clearReviewState } = reviewSlice.actions;

export default reviewSlice.reducer;
