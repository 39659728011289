import React from 'react';
import PropTypes from 'prop-types';

import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Step, Stack, Stepper, StepLabel, Typography, StepConnector } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { fDateTime } from 'src/utils/format-time';

function OrderTracking({ orderSingleView }) {
  const currentStatus = orderSingleView?.order_status;
  const isMobile = useResponsive('sm', 'xs');

  const allSteps = {
    PROCESSING: {
      label: 'PROCESSING',
      date: fDateTime(orderSingleView?.createdAt),
      title: 'Processing',
    },
    SHIPPED: {
      label: 'SHIPPED',
      date: fDateTime(orderSingleView?.shipped_at),
      title: 'Shipping',
    },
    DELIVERED: {
      label: 'DELIVERED',
      date: fDateTime(orderSingleView?.delivered_at),
      title: 'Delivered',
    },
    CANCEL_INITIATED: {
      label: 'CANCEL_INITIATED',
      date: fDateTime(orderSingleView?.cancel_initiated_at),
      title: 'Cancel Initiated',
    },
    CANCELLED: {
      label: 'CANCELLED',
      date: fDateTime(orderSingleView?.cancelled_at),
      title: 'cancelled',
    },
    RETURN_INITIATED: {
      label: 'RETURN_INITIATED',
      date: fDateTime(orderSingleView?.return_initiated_at),
      title: 'Return Initiated',
    },
    RETURNED: {
      label: 'RETURNED',
      date: fDateTime(orderSingleView?.returned_at),
      title: 'Returned',
    },
  };

  // Map of visible step sequences for each status
  const stepSequences = {
    CANCEL_INITIATED: ['PROCESSING', 'CANCEL_INITIATED'],
    CANCELLED: ['PROCESSING', 'CANCELLED'],
    RETURN_INITIATED: ['PROCESSING', 'SHIPPED', 'DELIVERED', 'RETURN_INITIATED'],
    RETURNED: ['PROCESSING', 'SHIPPED', 'DELIVERED', 'RETURN_INITIATED', 'RETURNED'],
    DEFAULT: ['PROCESSING', 'SHIPPED', 'DELIVERED'],
  };

  const visibleSteps = (stepSequences[currentStatus] || stepSequences.DEFAULT).map(
    (key) => allSteps[key]
  );

  const activeStepIndex = visibleSteps.findIndex((step) => step.label === currentStatus);

  const handleTop = (title) => {
    if (isMobile) {
      switch (title) {
        case 'Return Initiated':
        case 'Cancel Initiated':
          return '-50px';

        default:
          return '-35px';
      }
    }
    return '-35px';
  };

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStepIndex}
      connector={<StepConnector />}
      sx={{ width: '100%', my: 2, mt: 1 }}
    >
      {visibleSteps.map((step, index) => (
        <Step key={index} completed={index <= activeStepIndex}>
          <StepLabel
            icon={
              index <= activeStepIndex ? (
                <CheckCircleIcon color="success" />
              ) : (
                <CircleIcon color="disabled" />
              )
            }
            sx={{
              position: 'relative',
            }}
          >
            <Stack alignItems="center" spacing={0.5}>
              <Typography variant="caption" color="textSecondary">
                {step?.date}
              </Typography>
              <Stack
                sx={{
                  position: 'absolute',
                  top: handleTop(step?.title),
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: activeStepIndex === index ? '500' : '200' }}
                >
                  {step?.title}
                </Typography>
              </Stack>
            </Stack>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default OrderTracking;

OrderTracking.propTypes = {
  orderSingleView: PropTypes.object.isRequired,
};
