import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Stack, Divider, Typography } from '@mui/material';

import { getAllProduct } from 'src/server/api/product';
import OspImageUploader from 'src/pages/Common/upload';

import { useSettingsContext } from 'src/components/settings';
import OspSelectAutocomplete from 'src/components/hook-form/osp-select';
import { RHFSwitch, RHFTextField, RHFRadioGroup } from 'src/components/hook-form';

function BasicDetails({ isUpdate, control, imagePreview, setImagePreview }) {
  const dispatch = useDispatch();
  const theme = useSettingsContext();

  const allProducts = useSelector((state) => state.product.products);

  const [searchText, setSearchText] = useState();

  useEffect(() => {
    const filterSortObj = {
      filter_data: { is_deleted: false },
      sort_data: '',
    };
    const credentials = {
      page: 1,
      search: searchText,
      dispatch,
      limit: 10,
      state: filterSortObj,
    };
    dispatch(getAllProduct(credentials));
  }, [dispatch, searchText]);

  const optionProduct = [
    ...(allProducts?.products || []).map((results) => ({
      // label: results?.name?.english,
      label: `${results?.sap_id || ''} - ${results?.name?.english || ''}`,
      images: results?.images,
      value: results?.uid,
    })),
  ];
  const handleTextFieldChangeProduct = (value) => {
    setSearchText(value);
  };

  const TYPE_OPTIONS = [
    { value: 'INTERNAL', label: 'Internal' },
    { value: 'EXTERNAL', label: 'External' },
  ];

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="end" pt={2}>
        {isUpdate && <RHFSwitch name="is_active" color="success" label="Publish" />}
        <RHFSwitch name="is_featured" color="success" label="Featured" />
      </Stack>
      <Stack spacing={3} sx={{ px: 2, mt: 0 }}>
        <Typography variant="subtitle2" sx={{ mb: 0 }}>
          Title
          <Divider sx={{ mt: 0.5 }} />
        </Typography>
        <Grid container maxWidth="xl" spacing={2}>
          <Grid xs={12} md={6} item>
            <RHFTextField
              name="title.english"
              label="English"
              type="string"
              placeholder="English"
              size="small"
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack className={theme?.themeMode === 'dark' ? 'input-arabic-dark' : 'input-arabic'}>
              <RHFTextField
                name="title.arabic"
                label="Arabic"
                type="string"
                size="small"
                placeholder="Arabic Name"
              />
            </Stack>
          </Grid>
        </Grid>
        <Typography variant="subtitle2" sx={{ mb: 0 }}>
          Subtitle
          <Divider sx={{ mt: 0.5 }} />
        </Typography>
        <Grid container maxWidth="xl" spacing={2}>
          <Grid xs={12} md={6} item>
            <RHFTextField
              name="subtitle.english"
              label="English"
              multiline
              rows={3}
              type="string"
              placeholder="English"
              size="small"
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack className={theme?.themeMode === 'dark' ? 'input-arabic-dark' : 'input-arabic'}>
              <RHFTextField
                name="subtitle.arabic"
                label="Arabic"
                multiline
                rows={3}
                type="string"
                size="small"
                placeholder="Arabic Name"
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack>
          <Typography variant="subtitle2" mt={2.5} pl={1} sx={{ fontWeight: '600' }}>
            Image
          </Typography>
          <Divider sx={{ pt: 2, mt: 0, mb: -2 }} />
          <Grid container>
            <Grid item>
              <Stack sx={{ pt: 3 }} spacing={0} width="100%" direction="column">
                <OspImageUploader
                  setImagesPreview={setImagePreview}
                  imagesPreview={imagePreview}
                  single
                  folder="blogs"
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Grid container spacing={2}>
          <Grid item md={3} lg={3}>
            {' '}
            {/* This is the correct use of the 'item' prop */}
            <Stack spacing={5} pl={2} direction="row" alignItems="center">
              <RHFRadioGroup
                row
                spacing={1}
                name="link_type"
                color="success"
                options={TYPE_OPTIONS}
              />
            </Stack>
          </Grid>

          <Grid item md={9} lg={9}>
            {' '}
            {/* This is also correctly using the 'item' prop */}
            <Stack spacing={1} direction="row" alignItems="center">
              <RHFTextField
                name="link"
                label="Link"
                type="string"
                placeholder="Enter Link"
                size="small"
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <OspSelectAutocomplete
              name="products"
              control={control}
              options={optionProduct}
              label="Product"
              multiple
              placeholder="Search a product"
              handleTextFieldChange={handleTextFieldChangeProduct}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

BasicDetails.propTypes = {
  control: PropTypes.any,
  isUpdate: PropTypes.string,
  imagePreview: PropTypes.any,
  setImagePreview: PropTypes.func,
};

export default BasicDetails;
