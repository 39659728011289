import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { fNumber } from 'src/utils/format-number';

import { varAlpha, stylesMode } from 'src/theme/styles';

// ----------------------------------------------------------------------

export function SubWidgets({
  title,
  previousValue,
  liveValue,
  chart,
  chartType,
  chartWidth,
  color,
  cardImage,
  sx,
  bgColors,
  miniImage,
  filterValue,
  ...other
}) {
  const theme = useTheme();
  const cleanValue = filterValue.replace(/\bthis\b/i, '').trim();
  const displayValue = filterValue === 'Today' ? 'Yesterday' : `Last ${cleanValue}`;

  const renderTrending = (
    <Box sx={{ gap: 0.5, display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        sx={{
          width: 24,
          height: 24,
          display: 'flex',
          borderRadius: '50%',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: bgColors,
          color: 'success.dark',
          [stylesMode.dark]: { color: 'success.light' },
          ...(previousValue < 0 && {
            bgcolor: varAlpha(theme?.vars?.palette?.error?.mainChannel, 0.16),
            color: 'error.dark',
            [stylesMode.dark]: { color: 'error.light' },
          }),
        }}
      >
        <CardMedia
          component="img"
          src={miniImage}
          sx={{
            objectFit: 'cover',
            height: 15,
            width: 15,
          }}
        />
      </Box>
      &nbsp;
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {typeof previousValue === 'number' && !Number.isInteger(previousValue)
          ? previousValue.toFixed(2)
          : previousValue || 0}
      </Box>
      <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
        {displayValue}
      </Box>
    </Box>
  );

  return (
    <Card
      sx={{
        p: 3,
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      {...other}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ typography: 'subtitle2' }}>{title}</Box>
        <Box sx={{ my: 1.5, typography: 'h3' }}>{fNumber(liveValue)}</Box>
        {renderTrending}
      </Box>

      <Box>
        <CardMedia
          component="img"
          src={cardImage}
          sx={{
            height: 100,
            width: 100,
            objectFit: 'contain',
          }}
        />
      </Box>
    </Card>
  );
}

SubWidgets.propTypes = {
  title: PropTypes.string,
  chart: PropTypes.func,
  liveValue: PropTypes.number,
  previousValue: PropTypes.any,
  chartType: PropTypes.any,
  color: PropTypes.any,
  chartWidth: PropTypes.any,
  cardImage: PropTypes.any,
  sx: PropTypes.any,
  bgColors: PropTypes.any,
  miniImage: PropTypes.any,
  filterValue: PropTypes.any,
};
