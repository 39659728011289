import { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function useTable(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [dense, setDense] = useState(!!props?.defaultDense);

  const [page, setPage] = useState(0);

  const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || 'name');

  const [rowsPerPage, setRowsPerPage] = useState(
    location?.state?.limit || props?.defaultRowsPerPage
  );
  const [order, setOrder] = useState(props?.defaultOrder || 'asc');

  const [selected, setSelected] = useState(props?.defaultSelected || []);

  const onSort = useCallback(
    (id) => {
      const isAsc = orderBy === id && order === 'asc';
      if (id !== '') {
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
      }
    },
    [order, orderBy]
  );

  const onSelectRow = useCallback(
    (inputValue) => {
      const newSelected = selected.includes(inputValue)
        ? selected.filter((value) => value !== inputValue)
        : [...selected, inputValue];

      setSelected(newSelected);
    },
    [selected]
  );

  // useEffect(() => {
  //   setPage(location?.state?.pageValue || 0);
  // }, [location?.state?.pageValue]);

  const onChangeRowsPerPage = useCallback(
    (event) => {
      setPage(0);
      setRowsPerPage(parseInt(event.target.value, 10));
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('p', 1);
      searchParams.set('l', parseInt(event.target.value, 10) || rowsPerPage);
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        {
          state: {
            ...location.state,
            page: 0,
            limit: parseInt(event.target.value, 10) || rowsPerPage,
          },
        }
      );
      // navigate(
      //   {
      //     pathname: location.pathname,
      //     search: `?p=${page + 1}&L=${parseInt(event.target.value, 10) || rowsPerPage}`,
      //   },
      //   {
      //     state: {
      //       pageValue: page,
      //       limit: parseInt(event.target.value, 10) || rowsPerPage,
      //     },
      //   }
      // );
    },
    [location.pathname, location.search, location.state, navigate, rowsPerPage]
  );

  const onChangeDense = useCallback((event) => {
    setDense(event.target.checked);
  }, []);

  const onSelectAllRows = useCallback((checked, inputValue) => {
    if (checked) {
      setSelected(inputValue);
      return;
    }
    setSelected([]);
  }, []);

  const onChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('p', newPage + 1);
      searchParams.set('l', rowsPerPage);
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        {
          state: {
            ...location.state,
            page: newPage,
            limit: rowsPerPage,
          },
        }
      );
      // navigate(
      //   {
      //     pathname: location.pathname,
      //     search: `?p=${newPage + 1}&L=${rowsPerPage}`,
      //   },
      //   {
      //     state: {
      //       pageValue: newPage,
      //       limit: rowsPerPage,
      //     },
      //   }
      // );
    },
    [location.pathname, location.search, location.state, navigate, rowsPerPage]
  );

  const onResetPage = useCallback(() => {
    setPage(0);
  }, []);

  const onUpdatePageDeleteRow = useCallback(
    (totalRowsInPage) => {
      setSelected([]);
      if (page) {
        if (totalRowsInPage < 2) {
          setPage(page - 1);
        }
      }
    },
    [page]
  );

  const onUpdatePageDeleteRows = useCallback(
    ({ totalRowsInPage, totalRowsFiltered }) => {
      const totalSelected = selected.length;

      setSelected([]);

      if (page) {
        if (totalSelected === totalRowsInPage) {
          setPage(page - 1);
        } else if (totalSelected === totalRowsFiltered) {
          setPage(0);
        } else if (totalSelected > totalRowsInPage) {
          const newPage = Math.ceil((totalRowsFiltered - totalSelected) / rowsPerPage) - 1;

          setPage(newPage);
        }
      }
    },
    [page, rowsPerPage, selected.length]
  );

  return {
    dense,
    order,
    page,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onResetPage,
    onChangeRowsPerPage,
    onUpdatePageDeleteRow,
    onUpdatePageDeleteRows,
    //
    setPage,
    setDense,
    setOrder,
    setOrderBy,
    setSelected,
    setRowsPerPage,
  };
}
