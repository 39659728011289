import { configureStore } from '@reduxjs/toolkit';

import appReducer from '../store/app';
import authReducer from '../store/auth';
import userReducer from '../store/user';
import blogReducer from '../store/blogs';
// new
import orderReducer from '../store/order';
import brandReducer from '../store/brand';
import pagesReducer from '../store/pages';
import couponReducer from '../store/coupon';
import commonReducer from '../store/common';
import regionReducer from '../store/region';
import reviewReducer from '../store/reviews';
import productReducer from '../store/product';
import seoItemReducer from '../store/seoData';
import groupSliceReducer from '../store/group';
import sectionsReducer from '../store/sections';
import bannerItemReducer from '../store/banner';
import currencyReducer from '../store/currency';
import categoryReducer from '../store/category';
import settingsReducer from '../store/settings';
import dashboardReducer from '../store/dashboard';
import collectionReducer from '../store/collection';
import taxCategoryReducer from '../store/taxCategory';
import specialSaleReducer from '../store/specialSale';
import pickupLocationReducer from '../store/pickupLocation';
import productGroupSliceReducer from '../store/productGroup';
import productLabelSliceReducer from '../store/productLabel';
import productVariationReducer from '../store/productVariation';
import productVariationValueReducer from '../store/productVariationValue';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    settings: settingsReducer,
    user: userReducer,
    product: productReducer,
    productVariation: productVariationReducer,
    productVariationValue: productVariationValueReducer,
    productLabel: productLabelSliceReducer,
    productGroup: productGroupSliceReducer,
    group: groupSliceReducer,
    category: categoryReducer,
    order: orderReducer,
    currency: currencyReducer,
    taxCategory: taxCategoryReducer,
    seoData: seoItemReducer,
    bannerData: bannerItemReducer,
    collection: collectionReducer,
    brand: brandReducer,
    specialSale: specialSaleReducer,
    review: reviewReducer,
    coupon: couponReducer,
    pickupLocation: pickupLocationReducer,
    pages: pagesReducer,
    section: sectionsReducer,
    blog: blogReducer,
    region: regionReducer,
  },
});
