import { createAsyncThunk } from '@reduxjs/toolkit';

import { put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const getAllCountry = createAsyncThunk(
  'country/list',
  async ({ page, dispatch, state, search, limit }) => {
    try {
      const URL = `/country/admin/all?page=${page || 1}&limit=${limit || 10}&search=${
        search || ''
      } `;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        // router.push({
        //   pathname: paths.dashboard?.reviews,
        //   query: {},
        // });
        // dispatch(activeSnack({ type: 'success', message: 'Country created successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCountrySort = createAsyncThunk(
  'country/sort/list',
  async ({ page, dispatch, state, search, limit }) => {
    try {
      const URL = `/country/admin/all?page=${page || 1}&limit=${limit || 10}&search=${
        search || ''
      } `;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        // router.push({
        //   pathname: paths.dashboard?.reviews,
        //   query: {},
        // });
        // dispatch(activeSnack({ type: 'success', message: 'Country created successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCountryDetails = createAsyncThunk(
  'country/detail',
  async ({ countryId, dispatch }) => {
    try {
      const URL = `/country/admin/single/${countryId}`;
      const response = await post(URL);
      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createCountry = createAsyncThunk(
  'country/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/country/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        handleClose();
        const filterDataObj = {
          filter_data: { is_deleted: false },
        };

        dispatch(
          getAllCountry({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            state: filterDataObj,
          })
        );

        dispatch(activeSnack({ type: 'success', message: 'Country Created Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateCountry = createAsyncThunk(
  'country/update',
  async ({ state, dispatch, handleClose, countryId }) => {
    try {
      const URL = `/country/admin/${countryId}`;
      const response = await put(URL, state);
      if (response?.isSuccess) {
        handleClose();
        const filterDataObj = {
          filter_data: { is_deleted: false },
        };

        dispatch(
          getAllCountry({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            state: filterDataObj,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'Country Updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteCountry = createAsyncThunk(
  'country/delete',
  async ({ state, dispatch, handleClose, countryId }) => {
    try {
      const URL = `country/admin/hard-delete/${countryId}`;
      const response = await del(URL, state);
      if (response?.isSuccess) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'Country Deleted Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const checkCountryNameValid = createAsyncThunk(
  'country/check/country-name',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/country/admin/english-title/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// state

export const getAllState = createAsyncThunk(
  'state/list',
  async ({ page, dispatch, state, search, limit }) => {
    console.log(page);

    try {
      const URL = `/state/admin/all?page=${page || 1}&limit=${limit || 10}&search=${search || ''} `;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        // router.push({
        //   pathname: paths.dashboard?.reviews,
        //   query: {},
        // });
        // dispatch(activeSnack({ type: 'success', message: 'State created successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllStateSort = createAsyncThunk(
  'state/sort/list',
  async ({ page, dispatch, state, search, limit }) => {
    console.log(page);

    try {
      const URL = `/state/admin/all?page=${page || 1}&limit=${limit || 10}&search=${search || ''} `;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        // router.push({
        //   pathname: paths.dashboard?.reviews,
        //   query: {},
        // });
        // dispatch(activeSnack({ type: 'success', message: 'State created successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getStateDetails = createAsyncThunk('state/detail', async ({ stateId, dispatch }) => {
  try {
    const URL = `/state/admin/single/${stateId}`;
    const response = await post(URL);
    if (response?.isSuccess) {
      return response;
    }
    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const createState = createAsyncThunk(
  'state/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/state/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        handleClose();
        const filterDataObj = {
          filter_data: { is_deleted: false },
        };

        dispatch(
          getAllState({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            state: filterDataObj,
          })
        );

        dispatch(activeSnack({ type: 'success', message: 'State Created Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateState = createAsyncThunk(
  'state/update',
  async ({ state, dispatch, handleClose, stateId }) => {
    try {
      const URL = `/state/admin/${stateId}`;
      const response = await put(URL, state);
      if (response?.isSuccess) {
        handleClose();
        const filterDataObj = {
          filter_data: { is_deleted: false },
        };

        dispatch(
          getAllState({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            state: filterDataObj,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'State Updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteState = createAsyncThunk(
  'state/delete',
  async ({ state, dispatch, handleClose, stateId }) => {
    try {
      const URL = `state/admin/hard-delete/${stateId}`;
      const response = await del(URL, state);
      if (response?.isSuccess) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'State Deleted Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const checkStateNameValid = createAsyncThunk(
  'state/check/state-name',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/state/admin/english-title/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// district
export const getAllDistrict = createAsyncThunk(
  'district/list',
  async ({ page, dispatch, limit, state }) => {
    try {
      const URL = `/district/admin/all?page=${page || 1}&limit=${limit || 10}&search=${''} `;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        // router.push({
        //   pathname: paths.dashboard?.reviews,
        //   query: {},
        // });
        // dispatch(activeSnack({ type: 'success', message: 'District created successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getDistrictDetails = createAsyncThunk(
  'district/detail',
  async ({ districtId, dispatch }) => {
    try {
      const URL = `/district/admin/single/${districtId}`;
      const response = await post(URL);
      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createDistrict = createAsyncThunk(
  'district/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/district/admin/new`;
      const response = await post(URL, state);
      if (response?.isSuccess) {
        handleClose();
        const filterDataObj = {
          filter_data: { is_deleted: false },
        };

        dispatch(
          getAllDistrict({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            district: filterDataObj,
          })
        );

        dispatch(activeSnack({ type: 'success', message: 'District Created Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateDistrict = createAsyncThunk(
  'district/update',
  async ({ state, dispatch, handleClose, districtId }) => {
    try {
      const URL = `/district/admin/${districtId}`;
      const response = await put(URL, state);
      if (response?.isSuccess) {
        handleClose();
        const filterDataObj = {
          filter_data: { is_deleted: false },
        };

        dispatch(
          getAllDistrict({
            page: 1,
            search: '',
            dispatch,
            limit: 10,
            district: filterDataObj,
          })
        );
        dispatch(activeSnack({ type: 'success', message: 'District Updated Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteDistrict = createAsyncThunk(
  'district/delete',
  async ({ state, dispatch, handleClose, districtId }) => {
    try {
      const URL = `district/admin/hard-delete/${districtId}`;
      const response = await del(URL, state);
      if (response?.isSuccess) {
        handleClose();
        dispatch(activeSnack({ type: 'success', message: 'District Deleted Successfully' }));
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const checkDistrictNameValid = createAsyncThunk(
  'district/check/district-name',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/district/admin/english-title/check`, state);
      if (response?.isSuccess) {
        return response;
      }

      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
