import { createSlice } from '@reduxjs/toolkit';

import {
  getDashboardData,
  getDashboardSyncData,
  getInventoryCountData,
  getSidebarItemCountData,
  getPartnerEarningChartData,
} from '../api/dashboard';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    error: {},
    loading: false,
    inventoryCount: {},
    chartLoading: false,
    dashboardData: {},
    syncLoading: false,
  },
  reducers: {
    clearDashboardError: (state) => {
      state.error = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryCountData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryCountData.fulfilled, (state, action) => {
        state.loading = false;
        state.inventoryCount = action.payload;
        state.error = {};
      })
      .addCase(getInventoryCountData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      .addCase(getSidebarItemCountData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSidebarItemCountData.fulfilled, (state, action) => {
        state.loading = false;
        state.sidebarCounts = action.payload;
        state.error = {};
      })
      .addCase(getSidebarItemCountData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      .addCase(getDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardData = action.payload;
        state.error = {};
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })

      .addCase(getDashboardSyncData.pending, (state) => {
        state.syncLoading = true;
      })
      .addCase(getDashboardSyncData.fulfilled, (state, action) => {
        state.syncLoading = false;
        state.dashboardData = action.payload;
        state.error = {};
      })
      .addCase(getDashboardSyncData.rejected, (state, action) => {
        state.syncLoading = false;
        state.error = action.error;
      })

      .addCase(getPartnerEarningChartData.pending, (state) => {
        state.chartLoading = true;
      })
      .addCase(getPartnerEarningChartData.fulfilled, (state, action) => {
        state.chartLoading = false;
        state.earningsChart = action.payload;
        state.error = {};
      })
      .addCase(getPartnerEarningChartData.rejected, (state, action) => {
        state.chartLoading = false;
        state.error = action.error;
      });
  },
});

export default dashboardSlice.reducer;
