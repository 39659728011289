import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, del, post } from '../http';
import { activeSnack } from '../store/common';

export const createProductVariationValue = createAsyncThunk(
  'product-variation-value/create',
  async ({ state, dispatch, handleClose, handleClear, filterData }) => {
    try {
      const URL = `/product-variation-value/admin/new`;
      const response = await post(URL, state);
      const filterDataObj = {
        filter_data: filterData,
      };
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        dispatch(
          getAllProductVariationValue({
            page: 1,
            search: '',
            dispatch,
            limit: sessionStorage.getItem('rowsPerPage_product_variation_value'),
            state: filterDataObj,
          })
        );
        dispatch(
          activeSnack({ type: 'success', message: 'Product Variation Value Created Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProductVariationValue = createAsyncThunk(
  'product-variation-value/list',
  async ({ page, search, dispatch, limit, state }) => {
    try {
      const response = await post(
        `product-variation-value/admin/all?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }`,
        state
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProductVariationValueDetails = createAsyncThunk(
  'product-variation-value/single',
  async ({ productVariationValueId, dispatch }) => {
    try {
      const response = await get(
        `/product-variation-value/admin/single/${productVariationValueId}`
      );
      if (response?.isSuccess) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateProductVariationValueDetails = createAsyncThunk(
  'product-variation-value/update',
  async ({ state, productVariationValueId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/product-variation-value/admin/${productVariationValueId}`;
      const response = await put(URL, state);
      if (response?.isSuccess) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }

        dispatch(
          activeSnack({ type: 'success', message: 'Product Variation Value Updated Successfully' })
        );
        return response;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const archiveProductVariationValue = createAsyncThunk(
  'product-variation-value/archive',
  async ({ productVariationValueId, dispatch }) => {
    try {
      const response = await post(
        `/product-variation-value/admin/delete/${productVariationValueId}`
      );

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Product Archived' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const restoreProductVariationValue = createAsyncThunk(
  'product-variation-value/unarchive',
  async ({ productVariationValueId, dispatch }) => {
    try {
      const response = await post(
        `/product-variation-value/admin/restore/${productVariationValueId}`
      );

      if (response?.isSuccess) {
        dispatch(activeSnack({ type: 'success', message: 'Product Restored' }));
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteProductVariationValue = createAsyncThunk(
  'product-variation-value/delete',
  async ({ productVariationValueId, dispatch, handleClose }) => {
    try {
      const response = await del(
        `/product-variation-value/admin/hard-delete/${productVariationValueId}/`
      );
      if (response?.isSuccess) {
        if (handleClose()) {
          handleClose();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'Product Variation Value Deleted Successfully' })
        );
        return response?.uid;
      }
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getProductVariationValueCounts = createAsyncThunk(
  'product-variation-value/count',
  async ({ dispatch, state }) => {
    try {
      const response = await post(`/product-variation-value/admin/count/all`, state);

      if (response?.isSuccess) {
        return response;
      }
      // know errors
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
      return response?.message;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
