import { createAsyncThunk } from '@reduxjs/toolkit';

import { put, post } from '../http';
import { activeSnack } from '../store/common';

export const createSeoItem = createAsyncThunk('seo/item/create', async ({ state, dispatch }) => {
  try {
    const URL = `/seo-item/admin/new`;
    const response = await post(URL, state);

    if (response?.isSuccess) {
      dispatch(activeSnack({ type: 'success', message: 'SEO Data Updated Successfully' }));
      return response;
    }

    // know errors
    dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getSeoItemModule = createAsyncThunk('get/seo/item', async ({ state, dispatch }) => {
  try {
    const URL = `/seo-item/admin/module/single`;
    const response = await post(URL, state);

    if (response?.isSuccess) {
      return response;
    }

    // know errors
    // dispatch(activeSnack({ type: 'error', message: response?.message || 'something went wrong' }));
    if (response?.message !== '' && response?.message !== "Seo Item doesn't exist with this id.") {
      dispatch(
        activeSnack({ type: 'error', message: response?.message || 'something went wrong' })
      );
    }
    // console.log(response?.message);
    return response?.message;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const updateSeoItemModule = createAsyncThunk(
  'seo-item/update',
  async ({ state, seoId, dispatch }) => {
    try {
      const URL = `/seo-item/admin/${seoId}`;

      const response = await put(URL, state);
      if (response) {
        // dispatch(
        //   getAllBrand({
        //     page: 1,
        //     search: '',
        //     dispatch,
        //     limit: sessionStorage.getItem('rowsPerPage_brand'),
        //     state: '',
        //   })
        // );
        dispatch(activeSnack({ type: 'success', message: 'SEO Data Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
